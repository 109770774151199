import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Popup from "../../components/Popup/Popup";
import AdminSideMenu from "../../components/AdminDashboard/AdminSideMenu";
import AdminResearchGrantDashboardMain from "./AdminResearchGrantDashBoardMain";


const AdminResearchGrantDashboard = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [popup, setPopup] = useState({ message: "", type: "", isVisible: false, buttonLabel: "", buttonRoute: "" });


  const token = localStorage.getItem('adminAuthToken');
  const [dashboard, setDashboard] = useState({});
  
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchDashboard = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/admin/dashboard`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setDashboard(response.data);
      } catch (error) {
        console.error("Error fetching dashboard:", error);
        setPopup({
          message: "Unable to fetch dashboard. Please try again later.",
          type: "error",
          isVisible: true,
          buttonLabel: "Retry",
          buttonRoute: "/admindashboard",
        });
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchDashboard();
  }, [token]);


  

 
  
  // Inline styles
  const styles = {
    dashboardContainer: {
      display: "flex",
      minHeight: "100vh",
    },
    adminSideMenuContainer: {
      flex: 1,
      padding: "20px",
      backgroundColor: "#f9f9f9",
      borderRight: "1px solid #ddd",
    },
    contentContainer: {
      flex: 3,
      padding: "20px",
      backgroundColor: "#fff",
    },
    adminSideMenu: {
      marginBottom: "20px",
    },
    heading: {
      marginBottom: "10px",
      color: "#333",
    },
    status: {
      fontSize: "14px",
      marginBottom: "20px",
    },
    statusOpen: {
      color: "green",
      fontWeight: "bold",
    },
    menuButton: {
      backgroundColor: "#ec3237",
      color: "white",
      border: "none",
      borderRadius: "5px",
      width: "80%",
      marginBottom: "10px",
      padding: "10px",
      cursor: "pointer",
    },
    applicationList: {
      marginBottom: "20px",
    },
    applicationListItem: {
      marginBottom: "10px",
    },
    applicationLink: {
      textDecoration: "none",
      color: "#0066cc",
    },
    applicationLinkHover: {
      textDecoration: "underline",
    },
    primaryButton: {
      backgroundColor: "#1db540",
      color: "white",
      padding: "10px 20px",
      border: "none",
      borderRadius: "5px",
      marginTop: "20px",
      cursor: "pointer",
    },
    hamburgerButton: {
      backgroundColor: "transparent",
      border: "none",
      fontSize: "24px",
      color: "#333",
      cursor: "pointer",
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const ApplicationList = () => (
    <div style={styles.applicationList}>
      <h2 style={styles.heading}>Admin Dashboard</h2>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          {typeof dashboard.message === "string" && <p>{dashboard.message}</p>}
          {Array.isArray(dashboard) && dashboard.length > 0 ? (
            <ul style={{ padding: "0", margin: "0", listStyle: "none" }}>
              {dashboard.map((app, index) => (
                <li key={index} style={styles.applicationListItem}>
                  {app.message}
                </li>
              ))}
            </ul>
          ) : (
            <p></p>
          )}

         <AdminResearchGrantDashboardMain/>
          
          {popup.isVisible && (
            <Popup
              message={popup.message}
              type={popup.type}
              onClose={() => setPopup({ ...popup, isVisible: false })}
              buttonLabel={popup.buttonLabel}
              buttonRoute={popup.buttonRoute}
            />
          )}
        </>
      )}
    </div>
  );

  return (
    <div style={styles.dashboardContainer}>
      {isMobile && (
        <button
          style={{
            ...styles.hamburgerButton,
            position: isMenuOpen ? "fixed" : "absolute",
            top: isMenuOpen ? "10px" : "20px",
            right: "20px",
            zIndex: 1000,
          }}
          onClick={toggleMenu}
        >
          {isMenuOpen ? "X" : "☰"}
        </button>
      )}
      {isMobile && isMenuOpen && <AdminSideMenu />}
      {!isMobile && <AdminSideMenu />}
      <div style={styles.contentContainer}>
        <ApplicationList />
      </div>
    </div>
  );
};

export default AdminResearchGrantDashboard;
