import React from 'react';
import Slider from "react-slick";

import SectionTitle from '../../components/SectionTitle'
import SingleTestimonial from '../../components/Testimonial/SingleTestimonial';
import RecipientImg1 from '../../assets/images/recipient/1.jpg';
import RecipientImg2 from '../../assets/images/recipient/2.jpg'; // Example additional import
import RecipientImg3 from '../../assets/images/recipient/3.jpg'; // Example additional import
import RecipientImg4 from '../../assets/images/recipient/4.png'; // Example additional import
import RecipientImg5 from '../../assets/images/recipient/5.jpg'; // Example additional import
import RecipientImg6 from '../../assets/images/recipient/6.jpg'; // Example additional import
import RecipientImg7 from '../../assets/images/recipient/7.jpg'; // Example additional import
import RecipientImg8 from '../../assets/images/recipient/8.jpg'; // Example additional import
import RecipientImg9 from '../../assets/images/recipient/9.png'; // Example additional import

import testiImg from '../../assets/images/testimonial/testimonial.png';
import comaImg from '../../assets/images/testimonial/coma.png';

const Testimonial = () => {

    const testimonialSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 1,
                }
            },
            {

                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                }
            }
        ]
    };

    return (
        <>
            <div className="react-clients react-clientso home-testimonial pt---90 pb---120">
                <div className="container">
                    <SectionTitle Title="Past Recipients" />
                    <div className="client-slider wow animate__fadeInUp" data-wow-duration="0.3s">
                        <Slider {...testimonialSettings}>
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={RecipientImg1 }
                                Title="Prof. Adekunle Bakare"
                                Designation="University of Ibadan"
                                Desc="Body burden of heavy metals and their association with biomarkers of DNA damage in school-age children who are living or informally working in a major electronic waste market in Nigeria"
                                ratingCount='4.9'
                                comaImg={comaImg}
                                amount="NGN 948,400.00"
                            />
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={RecipientImg2 }
                                Title="Dr. Joel Onoja"
                                Designation="Unversity of Lagos"
                                Desc="Anti-Alzheimerâ€™s Potential of Isolated Compounds from Stephania dinklagei (Engl.) Diels (Menispermaceae)"
                                ratingCount='4.9'
                                comaImg={comaImg}
                                amount="NGN 991,000.00"
                            />
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={RecipientImg3 }
                                Title="Dr. Airat Bakare"
                                Designation="Unversity of Lagos"
                                Desc="Activities of FSH-receptors on the restoration of reproductive indices in hyperprolactin models treated with green coconut water."
                                ratingCount='4.9'
                                comaImg={comaImg}
                                amount="NGN 995,000.00"
                            />
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={RecipientImg8 }
                                Title="Dr. Okunola Alabi"
                                Designation="Federal University of Technology Akure"
                                Desc="Comparative study on the environmental contamination and eukaryotic DNA damage induced by wastewater from natural and artificial fishponds in Nigeria"
                                ratingCount='4.9'
                                comaImg={comaImg}
                                amount="NGN994,300.00"
                            />
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={RecipientImg4 }
                                Title="Prof. Ike Ifelunni"
                                Designation="Unversity of Nigeria"
                                Desc="Family Child-Rearing Practices and Suicidal Ideation Among Undergraduates in Southeast, Nigeria"
                                ratingCount='4.9'
                                comaImg={comaImg}
                                amount="NGN 765,000.00"
                            />

<SingleTestimonial
                                itemClass="single-client"
                                itemImg={RecipientImg5 }
                                Title="Dr. Peter Ige"
                                Designation="Unversity of Ibadan"
                                Desc="PRODUCTION, CHARACTERISATION AND MODELING OF BRIQUETTES PRODUCED FROM MAIZE HUSK WASTES AS AN ALTERNATIVE SOURCE OF ENERGY TO FIREWOOD AND CHARCOAL TOWARDS CLEAN ENERGY MECHANISM IN IDO, OYO STATE, NIGERIA"
                                ratingCount='4.9'
                                comaImg={comaImg}
                                amount="NGN 620,000.00"
                            />

<SingleTestimonial
                                itemClass="single-client"
                                itemImg={RecipientImg6 }
                                Title="Dr. Kayode Ileke"
                                Designation="Federal University of Technology Akure"
                                Desc="Response of Malaria Vector (Anopheles gambiae s. l.) to Crateva adansonii Extracts"
                                ratingCount='4.9'
                                comaImg={comaImg}
                                amount="NGN 668,500.00"
                            />

<SingleTestimonial
                                itemClass="single-client"
                                itemImg={RecipientImg7 }
                                Title="Dr Chika Okonkwo"
                                Designation="Nnamdi Azikiwe University, Awka"
                                Desc="ON-FARM IMPLEMENTATION OF BIO-EDIBLE ANTIPATHOGEN HYDRO-COOL SYSTEM FOR POSTHARVEST TREATMENT OF TOMATO"
                                ratingCount='4.9'
                                comaImg={comaImg}
                                amount="NGN 925,000.00"
                            />

                        </Slider>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Testimonial