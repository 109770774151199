import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Popup from "../../components/Popup/Popup";
import ApplicationDetail from "../../components/Dashboard/UserApplicationDetail";
import checked from '../../assets/svgs/check_svg.svg'

const ResearchGrantDashboard = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [personalDetailsCompleted, setPersonalDetailsCompleted] = useState(false);
  const [institutionDetailsCompleted, setInstitutionDetailsCompleted] = useState(false);
  const [researchDetailsCompleted, setResearchDetailsCompleted] = useState(false);
  const [isLoadingDeatails, setIsLoadingDetails] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [popup, setPopup] = useState({ message: "", type: "", isVisible: false, buttonLabel: "", buttonRoute: "" });

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const checkDetails = async () => {
      setIsLoadingDetails(true)
      try {
        // Retrieve the token from localStorage
        const token = localStorage.getItem("authToken");
  
        // If the token is available, include it in the headers
        const headers = token ? { Authorization: `Bearer ${token}` } : {};
  
        // Fetch data for personal, institution, and research checks with Authorization headers
        const personalRes = await fetch(`${process.env.REACT_APP_URL}/api/user/personal-check`, { headers });
        const institutionRes = await fetch(`${process.env.REACT_APP_URL}/api/user/institution-check`, { headers });
        const researchRes = await fetch(`${process.env.REACT_APP_URL}/api/user/research-check`, { headers });
        console.log('this is personal check', personalDetailsCompleted)

        // Parse JSON responses
        const personalData = await personalRes.json();
        const institutionData = await institutionRes.json();
        const researchData = await researchRes.json();
  
        // Check if the response has 'data' field and set accordingly
        setPersonalDetailsCompleted(personalData.data);
        setInstitutionDetailsCompleted(institutionData.data);  // Assuming institution check returns a similar response format
        setResearchDetailsCompleted(researchData.data); // Assuming research check returns a similar response format
      } catch (error) {
        console.error("Error fetching details", error);
        const errorMessage = error?.message || "An unknown error occurred. Please try again.";

      //   setPopup({
      //     message: `error occured: ${errorMessage}`,
      //     type: "error",
      //     isVisible: true,
      //     buttonLabel: "Retry",
      //     buttonRoute: "/userdashboard/",
      // });

      
      } finally {
        setIsLoadingDetails(false)
      }
    };
  
    checkDetails();
  }, []);
  
  

  const handleMenuClick = (path) => {
    
    if (path === "/userdashboard/researchdetails" && !personalDetailsCompleted) {
      setPopup({
            message: `Please fill out your Personal Details first.`,
            type: "error",
            isVisible: true,
            buttonLabel: "Proceed to Personal Details",
            buttonRoute: "/userdashboard/personaldetails",
          });      }
          else if (path === "/userdashboard/researchdetails" && !institutionDetailsCompleted) {
  setPopup({
        message: `Please fill out your Institution Details first.`,
        type: "error",
        isVisible: true,
        buttonLabel: "Proceed to Institution Details",
        buttonRoute: "/userdashboard/institutiondetails",
      });      } else if (path === "/userdashboard/institutiondetails" && !personalDetailsCompleted) {
      setPopup({
        message: `Please fill out your Personal Details first.`,
        type: "error",
        isVisible: true,
        buttonLabel: "Proceed to Personal Details",
        buttonRoute: "/userdashboard/personaldetails",
      });  
    }
 else if (path === "/userdashboard/institutiondetails" && !personalDetailsCompleted) {
          setPopup({
            message: `Please fill out your Personal Details first.`,
            type: "error",
            isVisible: true,
            buttonLabel: "Proceed to Personal Details",
            buttonRoute: "/userdashboard/personaldetails",
          });  
        }
  else {
      navigate(path);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    window.location.href = "/login"; // Redirect to login page
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const SideMenu = () => (
    <div
      style={{
        ...styles.sideMenuContainer,
        display: isMobile && !isMenuOpen ? "none" : "block", // Hide on mobile unless menu is open
      }}
    >
      <h2 style={styles.heading}>Get Started</h2>
      <p style={styles.status}>
        <strong>
          Application Status: <span style={styles.statusOpen}>Open</span>
        </strong>
      </p>
      {[
  { text: "Home", path: "/userdashboard" },
  {
    text: "Personal Details",
    path: "/userdashboard/personaldetails",
    isCompleted: personalDetailsCompleted,
  },
  {
    text: "Institution Details",
    path: "/userdashboard/institutiondetails",
    isCompleted: institutionDetailsCompleted,
  },
  {
    text: "Research Details",
    path: "/userdashboard/researchdetails",
    isCompleted: researchDetailsCompleted,
  },
].map((menu, index) => (
  <button
    key={index}
    style={{
      ...styles.menuButton,
      ...(menu.isCompleted && styles.disabledButton),
    }}
    onClick={() => handleMenuClick(menu.path)}
    disabled={menu.isCompleted} // Disable if the task is completed
  >
    {menu.isCompleted && (
      <img
        src={checked}
        alt="Completed"
        style={styles.completedIcon} // Icon style
      />
    )}
    {menu.text}
  </button>
))}


      <button style={styles.menuButton} onClick={() => handleLogout()}>
        Log Out
      </button>
    </div>
  );

  const ApplicationList = () => (
    <div style={styles.applicationList}>
      <ApplicationDetail />
    </div>
  );

  return (
    <div style={styles.dashboardContainer}>
      {/* Hamburger Button for mobile */}
      {isMobile && (
        <button
          style={{
            ...styles.hamburgerButton,
            position: isMenuOpen ? "fixed" : "absolute", // Fix button at top when menu is open
            top: isMenuOpen ? "10px" : "20px", // Adjust position when the menu is open
            right: "20px", // Position at the top right
            zIndex: 1000, // Ensure it's always above other elements
          }}
          onClick={toggleMenu}
        >
          {isMenuOpen ? "X" : "☰"}
        </button>
      )}

      {/* Side menu for larger screens */}
      {!isMobile && <SideMenu />}
      {/* Side menu for mobile screens */}
      {isMobile && isMenuOpen && <SideMenu />}
      
      <div style={styles.contentContainer}>
        <ApplicationList />
      </div>

      {popup.isVisible && (
        <Popup
          message={popup.message}
          type={popup.type}
          onClose={() => setPopup({ ...popup, isVisible: false })}
          buttonLabel={popup.buttonLabel}
          buttonRoute={popup.buttonRoute}
        />
      )}
    </div>
  );
};

const styles = {
  dashboardContainer: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column", // Default to column layout (mobile-first approach)
  },
  sideMenuContainer: {
    position: "fixed", // Fix side menu to the left of the screen for large screens
    top: "0",
    left: "0",
    width: "250px", // Fixed width for large screens
    height: "100vh", // Full height on larger screens
    padding: "20px",
    backgroundColor: "#f9f9f9",
    borderRight: "1px solid #ddd",
    zIndex: 1, // Ensure it stays above content
    transition: "transform 0.3s ease", // Smooth transition when the side menu is toggled
  },
  contentContainer: {
    flex: 1,
    padding: "20px",
    backgroundColor: "#fff",
    marginLeft: "0", // Initially, content takes the full width on mobile
    transition: "margin-left 0.3s ease", // Smooth transition when the menu is toggled
    minHeight: "100vh", // Ensure it takes full height
  },
  heading: {
    marginBottom: "10px",
    color: "#333",
  },
  status: {
    fontSize: "14px",
    marginBottom: "20px",
  },
  statusOpen: {
    color: "green",
    fontWeight: "bold",
  },
  menuButton: {
    backgroundColor: "#ec3237",
    color: "white",
    border: "none",
    borderRadius: "5px",
    width: "80%",
    marginBottom: "10px",
    padding: "10px",
    cursor: "pointer",
    display: "flex", // Aligns icon and text horizontally
    alignItems: "center",
  },
  disabledButton: {
    backgroundColor: "#cccccc", // Lighter background for disabled buttons
    color: "#777", // Lighter text for disabled buttons
    cursor: "not-allowed", // Show not-allowed cursor when disabled
  },
  completedIcon: {
    marginRight: "2px", // Add space between the icon and text
    width: "16px",
    height: "16px",
  },
  hamburgerButton: {
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    padding: "15px",
    cursor: "pointer",
    fontSize: "30px",
    textAlign: "center",
    alignSelf: "center",
    zIndex: 1000, // Ensure it appears above content
  },
  applicationList: {
    marginBottom: "20px",
  },

  // Styles for larger screens (side by side layout)
  '@media (min-width: 600px)': {
    dashboardContainer: {
      flexDirection: "row", // Side-by-side layout for large screens
    },
    sideMenuContainer: {
      display: "block", // Always display side menu for large screens
      position: "relative", // Position the menu normally for large screens
      width: "250px", // Fixed width for large screens
    },
    contentContainer: {
      flex: 1,
      marginLeft: "250px", // Ensure content takes the remaining space
    },
  },
};

export default ResearchGrantDashboard;
