import React, { useState, useEffect } from "react";
import { Routes, Route } from 'react-router-dom';
import Preloader from "../components/Preloader";
import ProtectedRoute from './ProtectedRoute';
import AdminProtectedRoute from "./AdminProctectedRoute";
import UpdateApplications from "../components/Dashboard/UpdateApplications";


// Custom Components
import Home from '../pages/home';
import About from '../pages/about';
import OTP from "../pages/authentication/otp";
import UserDashboard from "../pages/dashboard";

import AdminDashboard from "../pages/adminDashboard";
import ViewApplications from "../components/AdminDashboard/ViewApplications";
import AdminLogin from "../pages/authentication/adminLogin";
import UserHome from '../components/Dashboard/Userhome';
import PersonalDetails from '../components/Dashboard/UserPersonalDetails';
import ApplicationDetail from '../components/Dashboard/UserApplicationDetail';
import AdminApplicationDetail from "../components/AdminDashboard/AdminApplicationDetail";
import Login from '../pages/authentication/login';
import Signup from '../pages/authentication/signup';
import ForgotPassword from "../pages/authentication/forgotPassword";
import Contact from '../pages/contact';
import Error from '../pages/404';
import LoadTop from '../components/ScrollTop/LoadTop';
import InstitutionDetails from "../components/Dashboard/InstitutionDetails";
import ResearchDetails from "../components/Dashboard/ResearchDetails";

const App = () => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }, []);

    return (
        <div className='App'>
            {isLoading ? <Preloader /> : ''}
            <>
                <LoadTop />
                <Routes>
                <Route path="/login" element={<Login />} />
                 <Route path="/adminlogin" element={<AdminLogin />} />

                 <Route 
                    path="/admindashboard/*" 
                    element={
                        <AdminProtectedRoute>
                            <AdminDashboard />
                        </AdminProtectedRoute>
                    } 
                />
                <Route 
                    path="/userdashboard/*" 
                    element={
                        <ProtectedRoute>
                            <UserDashboard />
                        </ProtectedRoute>
                    } 
                />
                    <Route path="/" exact element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/otp" element={<OTP />} />

                    <Route path="/forgot-password" element={ <ForgotPassword/> } />
                    <Route path="/admindashboard/viewapplications" element={ <AdminProtectedRoute> <ViewApplications /> </AdminProtectedRoute>} />



                    <Route path="/userdashboard/personaldetails" element={ <ProtectedRoute> <PersonalDetails /> </ProtectedRoute>} />
                    <Route path="/userdashboard/institutiondetails" element={ <ProtectedRoute> <InstitutionDetails /> </ProtectedRoute>} />
                    <Route path="/userdashboard/researchdetails" element={ <ProtectedRoute> <ResearchDetails /> </ProtectedRoute>} />
                    <Route path="/userdashboard/updateapplications" element={ <ProtectedRoute> <UpdateApplications /> </ProtectedRoute>} />

                    <Route path="/userdashboard/applicationdetails/:id" element={<ProtectedRoute> <ApplicationDetail /> </ProtectedRoute>} /> {/* Updated path */}
                    <Route path="/application/:id" element={  <AdminProtectedRoute> <AdminApplicationDetail/> </AdminProtectedRoute>}  />

                    <Route path="/userhome" element={<UserHome />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path='*' element={<Error />} />
                </Routes>
            </>
        </div>
    );
}

export default App;
