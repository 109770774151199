import React from 'react';
import UserBreadcrumb from '../Breadcrumb/UserBreadcrumbs';
import UpdateApplicationsMain from './UpdateApplicationsMain';
import ScrollToTop from '../ScrollTop';

const UpdateApplications = () => {
    return (
        <div className="react-wrapper">
            <div className="react-wrapper-inner">
                <UserBreadcrumb pageTitle="Update Applications" />
                <UpdateApplicationsMain />
                <ScrollToTop />
            </div>
        </div>
    );
};

export default UpdateApplications;
