import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

import Popup from '../Popup/Popup';

const UpdateApplicationsMain = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const [institutionNames, setInstitutionNames] = useState([]);
  const [coApplicantsTitle, setCoApplicantsTitle] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [popup, setPopup] = useState({ message: "", type: "", isVisible: false, buttonLabel: "", buttonRoute: "" });
  const token = localStorage.getItem('authToken');

  const [formData, setFormData] = useState({
    cv: null,
    supportLetter: null,
    researchProposal: null,
    costing: null,
    coApplicants: [{ cv: null }, { cv: null }],  
  });

  const [errors, setErrors] = useState({
    cv: '',
    supportLetter: '',
    researchProposal: '',
    costing: '',
    coApplicants: []  

  });

  const [coApplicantExists, setCoApplicantExists] = useState({
    coApplicant1: false,
    coApplicant2: false
  });

  const navigate = useNavigate();

  // Fetch title for co-applicant
  useEffect(() => {
    const fetchTitle = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URL}/api/alltitles`);
        const names = response.data.map((item) => item.title);
        setCoApplicantsTitle(names);
      } catch (error) {
        console.error("Error fetching titles:", error);
      }
    };

    fetchTitle();
  }, []);

  // Fetch institution names
  useEffect(() => {
    const fetchInstitutionNames = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URL}/api/allschools`);
        const names = response.data.map(item => item.inst);
        setInstitutionNames(names);
      } catch (error) {
        console.error('Error fetching institution names:', error);
      }
    };

    fetchInstitutionNames();
  }, []);

  // Check for co-applicant and co-applicant2
  useEffect(() => {
    const checkCoApplicant = async () => {
      try {
        const coApplicantResponse = await axios.get(`${process.env.REACT_APP_URL}/api/user/co-applicant-check`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log('this is response co-app1', coApplicantResponse.data.data);
        if (coApplicantResponse.data.data) {
          setCoApplicantExists(prevState => ({
            ...prevState,
            coApplicant1: true
          }));
        }
      } catch (error) {
        console.error("Error checking co-applicant:", error);
      }
    };

    const checkCoApplicant2 = async () => {
      try {
        const coApplicant2Response = await axios.get(`${process.env.REACT_APP_URL}/api/user/co-applicant2-check`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log('this is response co-app2', coApplicant2Response.data.data);

        if (coApplicant2Response.data.data) {
          setCoApplicantExists(prevState => ({
            ...prevState,
            coApplicant2: true
          }));
        }
      } catch (error) {
        console.error("Error checking co-applicant2:", error);
      }
    };

    checkCoApplicant();
    checkCoApplicant2();
  }, [token]);

  const handleFileChange = (e, fieldName) => {
    const file = e.target.files[0];
    if (validateFile(file, fieldName)) {
      setFormData((prev) => ({ ...prev, [fieldName]: file }));
    }
  };

  const handleFileChangeCoApplicantCV = (index, e) => {
    const file = e.target.files[0];
    const updatedCoApplicants = [...formData.coApplicants];
  
    // Ensure that we have a valid object at the specified index
    if (updatedCoApplicants[index]) {
      if (file && file.type === 'application/pdf') {
        updatedCoApplicants[index].cv = file;
        setFormData({
          ...formData,
          coApplicants: updatedCoApplicants
        });
  
        // Ensure that errors.coApplicants is always an array before calling .map()
        setErrors({
          ...errors,
          coApplicants: errors.coApplicants?.map((err, idx) => (idx === index ? '' : err)) || []
        });
      } else {
        setErrors({
          ...errors,
          coApplicants: errors.coApplicants?.map((err, idx) => (idx === index ? 'Please upload a valid PDF file' : err)) || []
        });
      }
    }
  };
  
  const validateFile = (file, fieldName) => {
    if (!file) {
      setErrors((prev) => ({ ...prev, [fieldName]: 'File is required' }));
      return false;
    }
    if (!file.type.startsWith('application/pdf')) {
      setErrors((prev) => ({ ...prev, [fieldName]: 'Please upload a valid PDF file' }));
      return false;
    }
    setErrors((prev) => ({ ...prev, [fieldName]: '' }));
    return true;
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!formData.cv) {
      formErrors.cv = 'CV is required';
      isValid = false;
    }

    if (!formData.supportLetter) {
      formErrors.supportLetter = 'Institution Support letter is required';
      isValid = false;
    }

    if (!formData.costing) {
      formErrors.costing = 'Costing is required';
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!validateForm()) {
      return;
    }
  
    setIsLoading(true);
  
    try {
      const formDataObj = new FormData();
      formDataObj.append("cv", formData.cv);  // Main applicant's CV
      formDataObj.append("inst_sup_letter", formData.supportLetter);  // Institution support letter
  
      if (formData.researchProposal) {
        formDataObj.append("proposal", formData.researchProposal);  // Research proposal
      }
  
      if (formData.costing) {
        formDataObj.append("costing", formData.costing);  // Costing file
      }
  
      // Append co-applicant CVs
      if (formData.coApplicants && formData.coApplicants.length > 0) {
        // Co-applicant 1 CV
        if (formData.coApplicants[0]?.cv) {
          formDataObj.append("co_app_cv", formData.coApplicants[0].cv);
        }
  
        // Co-applicant 2 CV
        if (formData.coApplicants[1]?.cv) {
          formDataObj.append("co_app2_cv", formData.coApplicants[1].cv);
        }
      }
  
      // Submit the form data to the server
      const researchResponse = await fetch(`${process.env.REACT_APP_URL}/api/user/updateapplication`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formDataObj,
      });
  
      const researchResult = await researchResponse.json();
  
      if (!researchResponse.ok) {
        throw new Error(`Research submission failed: ${JSON.stringify(researchResult)}`);
      }
  
      // Show success popup
      setPopup({
        message: "Your Research Grant Application has been received!",
        type: "success",
        isVisible: true,
        buttonLabel: "Proceed to your Dashboard",
        buttonRoute: "/userdashboard",
      });
  
    } catch (error) {
      // Show error popup
      setPopup({
        message: `Submission Failed: ${error?.message || "An unknown error occurred. Please try again."}`,
        type: "error",
        isVisible: true,
        buttonLabel: "Retry",
        buttonRoute: "/userdashboard/updateapplications",
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    window.location.href = '/login'; // Redirect to login page
};


  // Inline CSS styles
  const styles = {
      input: {
        width: "100%",
        padding: "10px",
        marginBottom: "45px",

        borderRadius: "4px",
        border: "1px solid #ccc",
      },
      
      button: {
        width: "48%",
        padding: "10px",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: "16px",
        marginTop: "10px",
      },
      prevButton: {
        backgroundColor: "#ec3237",
        color: "#fff",
        border: "none",
      },
      nextButton: {
        backgroundColor: "#4CAF50",
        color: "#fff",
        border: "none",
      },
      formRow: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "20px",
      },
      formColumn: {
        width: "48%",
        marginRight: "2%",
      },
      formColumnLast: {
        width: "48%",
        marginRight: "0",
      },
      error: {
        color: "red",
        fontSize: "12px",
        marginTop: "5px",
      },
      buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "20px",
      },
      textCenter: {
        fontSize: "18px",
        fontWeight: "bold",
      },
    };

// Inline styles2
const styles2 = {
  dashboardContainer: {
    display: "flex",
    minHeight: "100vh",
  },
  sideMenuContainer: {
    flex: 1,
    padding: "20px",
    backgroundColor: "#f9f9f9",
    borderRight: "1px solid #ddd",
  },
  contentContainer: {
    flex: 3,
    padding: "20px",
    backgroundColor: "#fff",
  },
  sideMenu: {
    marginBottom: "20px",
  },
  heading: {
    marginBottom: "10px",
    color: "#333",
  },
  status: {
    fontSize: "14px",
    marginBottom: "20px",
  },
  statusOpen: {
    color: "green",
    fontWeight: "bold",
  },
  menuButton: {
    backgroundColor: "#ec3237",
    color: "white",
    border: "none",
    borderRadius: "5px",
    width: "80%",
    marginBottom: "10px",
    padding: "10px",
    cursor: "pointer",
  },
  applicationList: {
    marginBottom: "20px",
  },
  applicationListItem: {
    marginBottom: "10px",
  },
  applicationLink: {
    textDecoration: "none",
    color: "#0066cc",
  },
  applicationLinkHover: {
    textDecoration: "underline",
  },
  primaryButton: {
    backgroundColor: "#1db540",
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    marginTop: "20px",
    cursor: "pointer",
  },
};

const SideMenu = () => {
  const handleMenuClick = (path) => {
    // Allow navigation to previous steps
    if (path === "/userdashboard" || path === "/userdashboard/personaldetails" || path === "/userdashboard/researchdetails") {
      navigate(path);
    } else {
      // Check if the form is valid before navigating to the next step
     
        navigate(path);
       {
        // alert("Please complete the current form before proceeding!");
      }
    }
  };

  return (
      <div style={styles2.sideMenuContainer}>
        <h2 style={styles2.heading}>Get Started</h2>
        <p style={styles2.status}>
          <strong>
            Application Status: <span style={styles.statusOpen}>Open</span>
          </strong>
        </p>
        {[
          { text: "Home", path: "/userdashboard" },
          { text: "Personal Details", path: "/userdashboard/personaldetails" },
          { text: "Institution Details", path: "/userdashboard/institutiondetails" },
          { text: "Research Details", path: "/userdashboard/researchdetails" },

        ].map((menu, index) => (
          <button
            key={index}
            style={styles2.menuButton}
            onClick={() => handleMenuClick(menu.path)}
          >
            {menu.text}
          </button>
        ))}
        <button
                style={styles2.menuButton}
                onClick={() => handleLogout()}
              >
                Log Out
              </button>
      </div>
    );
  };
  return (
    <div style={styles2.dashboardContainer}>
      {!isMobile && <SideMenu />}
      <div style={styles2.contentContainer}>
        <div style={styles2.applicationList}>
          <form onSubmit={handleSubmit}>
            <div className="login-top">
              <h3>Update Application</h3>
            </div>

            {/* Regular fields */}
            <div className="form-group">
              <label>Upload Research Proposal</label>
              <input
                type="file"
                id="researchProposal"
                name="researchProposal"
                accept="application/pdf"
                onChange={(e) => handleFileChange(e, 'researchProposal')}
                style={styles.input}
              />
              {errors.researchProposal && (
                <span className="error" style={styles.error}>
                  {errors.researchProposal}
                </span>
              )}
            </div>

            <div className="form-group">
              <label>Upload Academic CV: </label>
              <input
                type="file"
                accept="application/pdf"
                onChange={(e) => handleFileChange(e, 'cv')}
                style={styles.input}
              />
              {errors.cv && (
                <span className="error" style={styles.error}>
                  {errors.cv}
                </span>
              )}
            </div>

            <div className="form-group">
              <label>Upload Letter from Institution Recommending You for Grant:</label>
              <input
                type="file"
                accept="application/pdf"
                onChange={(e) => handleFileChange(e, 'supportLetter')}
                style={styles.input}
              />
              {errors.supportLetter && (
                <span className="error" style={styles.error}>
                  {errors.supportLetter}
                </span>
              )}
            </div>

            <div className="form-group">
              <label>Upload Costing</label>
              <input
                type="file"
                id="costing"
                name="costing"
                accept="application/pdf"
                onChange={(e) => handleFileChange(e, 'costing')}
                style={styles.input}
              />
              {errors.costing && (
                <span className="error" style={styles.error}>
                  {errors.costing}
                </span>
              )}
            </div>

            {/* Conditionally render co-applicant CV */}
            {coApplicantExists.coApplicant1 && (
              <div className="form-group">
                <label>Co-applicant CV: </label>
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => handleFileChangeCoApplicantCV(0, e)}
                  style={styles.input}
                />
              </div>
            )}

            {coApplicantExists.coApplicant2 && (
              <div className="form-group">
                <label>Co-applicant2 CV: </label>
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => handleFileChangeCoApplicantCV(1, e)}
                  style={styles.input}
                />
              </div>
            )}

            <div style={styles.buttonContainer}>
              <button
                type="submit"
                style={{ ...styles.button, ...styles.nextButton }}
                disabled={isLoading}
              >
                {isLoading ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </form>
        </div>
      </div>
      {popup.isVisible && (
        <Popup
          message={popup.message}
          type={popup.type}
          onClose={() => setPopup({ ...popup, isVisible: false })}
          buttonLabel={popup.buttonLabel}
          buttonRoute={popup.buttonRoute}
        />
      )}
    </div>
  );
};

export default UpdateApplicationsMain;
