
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Popup from "../../components/Popup/Popup";

const AdminResearchGrantDashboardMain = () => {
  const [popup, setPopup] = useState({ message: "", type: "", isVisible: false, buttonLabel: "", buttonRoute: "" });
  const [formData, setFormData] = useState({ season: '' });
  const [errors, setErrors] = useState({ season: "" });
  const token = localStorage.getItem('adminAuthToken');
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    
    
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
          ...formData,
          [name]: type === "checkbox" ? checked : value,
        });
      };
    
      const validateForm = () => { 
        let formErrors = {};
        let isValid = true;
    
        if (!formData.season) {
            formErrors.season = 'Season is required';
            isValid = false;
        } else if (!/^\d{4}\/\d{4}$/.test(formData.season)) {
            formErrors.season = 'Season must be in the format YYYY/YYYY';
            isValid = false;
        } else {
            const [startYear, endYear] = formData.season.split('/').map(Number);
    
            if (endYear - startYear !== 1) {
                formErrors.season = 'Season years must be consecutive (e.g., 2024/2025)';
                isValid = false;
            }
        }
    
        setErrors(formErrors);
        return isValid;
      };
    
const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({}); // Clear any previous errors before re-validation
  
    if (validateForm()) { // Only submit if the form is valid
      setIsLoadingSubmit(true);
      const data = { season: formData.season };
  
      try {
          const response = await fetch(`${process.env.REACT_APP_URL}/api/admin/create-season`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data)
          });
          const result = await response.json();
          if (response.ok) {
              setPopup({
                  message: "Season Submitted successfully!",
                  type: "success",
                  isVisible: true,
                  buttonLabel: "Proceed to View All Applications",
                  buttonRoute: "/admindashboard/viewapplication",
              });
          } else {
              const errorMessages = result.message;
              setPopup({
                  message: `Season Submission Failed: ${errorMessages}`,
                  type: "error",
                  isVisible: true,
                  buttonLabel: "Retry",
                  buttonRoute: "/admindashboard",
              });
          }
      } catch (error) {
          console.error('Error Submitting Season:', error);
          setPopup({
              message: "An error occurred. Please try again.",
              type: "error",
              isVisible: true,
              buttonLabel: "Retry",
              buttonRoute: "/admindashboard",
          });
      } finally {
          setIsLoadingSubmit(false);
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('adminAuthToken');
    window.location.href = '/adminlogin'; // Redirect to login page
  };

    // Inline styles
    const styles = {
        dashboardContainer: {
          display: "flex",
          minHeight: "100vh",
        },
        adminSideMenuContainer: {
          flex: 1,
          padding: "20px",
          backgroundColor: "#f9f9f9",
          borderRight: "1px solid #ddd",
        },
        contentContainer: {
          flex: 3,
          padding: "20px",
          backgroundColor: "#fff",
        },
        adminSideMenu: {
          marginBottom: "20px",
        },
        heading: {
          marginBottom: "10px",
          color: "#333",
        },
        status: {
          fontSize: "14px",
          marginBottom: "20px",
        },
        statusOpen: {
          color: "green",
          fontWeight: "bold",
        },
        menuButton: {
          backgroundColor: "#ec3237",
          color: "white",
          border: "none",
          borderRadius: "5px",
          width: "80%",
          marginBottom: "10px",
          padding: "10px",
          cursor: "pointer",
        },
        applicationList: {
          marginBottom: "20px",
        },
        applicationListItem: {
          marginBottom: "10px",
        },
        applicationLink: {
          textDecoration: "none",
          color: "#0066cc",
        },
        applicationLinkHover: {
          textDecoration: "underline",
        },
        primaryButton: {
          backgroundColor: "#1db540",
          color: "white",
          padding: "10px 20px",
          border: "none",
          borderRadius: "5px",
          marginTop: "20px",
          cursor: "pointer",
        },
        hamburgerButton: {
          backgroundColor: "transparent",
          border: "none",
          fontSize: "24px",
          color: "#333",
          cursor: "pointer",
        }
      };

return (
  
  <div className="container">
  <div className="row">
    <div className="col-lg-12">
      <div className="login-right-form">
        <form onSubmit={handleSubmit}>
          <p>
            <label>Enter Season: </label>
            <div>
              <input
                placeholder="e.g., 2024/2025"
                type="text"
                id="season"
                name="season"
                value={formData.season}
                onChange={handleInputChange}
              />
              <button type="submit" id="button" name="submit" disabled={isLoadingSubmit}>
                {isLoadingSubmit ? "Submitting..." : "Submit"}
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right">
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </button>
            </div>
            {errors.season && <span className="error" style={{ color: "red" }}>{errors.season}</span>}
          </p>
        </form>
      </div>
    </div>
  </div>
  {popup.isVisible && (
            <Popup
              message={popup.message}
              type={popup.type}
              onClose={() => setPopup({ ...popup, isVisible: false })}
              buttonLabel={popup.buttonLabel}
              buttonRoute={popup.buttonRoute}
            />
          )}
</div>
)

    };

    export default AdminResearchGrantDashboardMain;