import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import checked from '../../assets/svgs/check_svg.svg'


import Popup from '../Popup/Popup';


const ResearchDetailsMain = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    const [institutionNames, setInstitutionNames] = useState([]);
    const [coApplicantsTitle, setCoApplicantsTitle] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
     const [personalDetailsCompleted, setPersonalDetailsCompleted] = useState(false);
            const [institutionDetailsCompleted, setInstitutionDetailsCompleted] = useState(false);
            const [researchDetailsCompleted, setResearchDetailsCompleted] = useState(false);
            const [isMenuOpen, setIsMenuOpen] = useState(false);
    
    
    const [popup, setPopup] = useState({ message: "", type: "", isVisible: false, buttonLabel: "", buttonRoute: "" });
    const token = localStorage.getItem('authToken');

    useEffect(() => {
                const checkDetails = async () => {
                  try {
                      
                    // If the token is available, include it in the headers
                    const headers = token ? { Authorization: `Bearer ${token}` } : {};
              
                    // Fetch data for personal, institution, and research checks with Authorization headers
                    const personalRes = await fetch(`${process.env.REACT_APP_URL}/api/user/personal-check`, { headers });
                    const institutionRes = await fetch(`${process.env.REACT_APP_URL}/api/user/institution-check`, { headers });
                    const researchRes = await fetch(`${process.env.REACT_APP_URL}/api/user/research-check`, { headers });
                    // Parse JSON responses
                    const personalData = await personalRes.json();
                    console.log('this is personal check', personalDetailsCompleted)
          
                    const institutionData = await institutionRes.json();
                    const researchData = await researchRes.json();
              
                    // Check if the response has 'data' field and set accordingly
                    setPersonalDetailsCompleted(personalData.data);
                    setInstitutionDetailsCompleted(institutionData.data);  // Assuming institution check returns a similar response format
                    setResearchDetailsCompleted(researchData.data); // Assuming research check returns a similar response format
                  } catch (error) {
                    console.error("Error fetching details", error);
                    const errorMessage = error?.message || "An unknown error occurred. Please try again.";
            
                  //   setPopup({
                  //     message: `error occured: ${errorMessage}`,
                  //     type: "error",
                  //     isVisible: true,
                  //     buttonLabel: "Retry",
                  //     buttonRoute: "/userdashboard/researchdetails",
                  // });
                  }
                };
              
                checkDetails();
              }, []);
              
          
              const handleMenuClick = (path) => {
                if (path === "/userdashboard/researchdetails" && !personalDetailsCompleted) {
                  setPopup({
                        message: `Please fill out your Personal Details first.`,
                        type: "error",
                        isVisible: true,
                        buttonLabel: "Proceed to Personal Details",
                        buttonRoute: "/userdashboard/personaldetails",
                      });      }
                      else if (path === "/userdashboard/researchdetails" && !institutionDetailsCompleted) {
              setPopup({
                    message: `Please fill out your Institution Details first.`,
                    type: "error",
                    isVisible: true,
                    buttonLabel: "Proceed to Institution Details",
                    buttonRoute: "/userdashboard/institutiondetails",
                  });      } else if (path === "/userdashboard/institutiondetails" && !personalDetailsCompleted) {
                  setPopup({
                    message: `Please fill out your Personal Details first.`,
                    type: "error",
                    isVisible: true,
                    buttonLabel: "Proceed to Personal Details",
                    buttonRoute: "/userdashboard/personaldetails",
                  });  
                } else {
                  navigate(path);
                }
              };
            
              const toggleMenu = () => {
                setIsMenuOpen(!isMenuOpen);
              };
          
    
    useEffect(() => {
      const fetchTitle = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_URL}/api/alltitles`
          );
          const names = response.data.map((item) => item.title);
          setCoApplicantsTitle(names);
        } catch (error) {
          console.error("Error fetching titles:", error);
        }
      };
  
      fetchTitle();
    }, []);



    useEffect(() => {
      const handleResize = () => setIsMobile(window.innerWidth <= 600);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);


       // Fetch institution names
       useEffect(() => {
        const fetchInstitutionNames = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_URL}/api/allschools`);
                const names = response.data.map(item => item.inst);
                setInstitutionNames(names);
            } catch (error) {
                console.error('Error fetching institution names:', error);
            }
        };

        fetchInstitutionNames();
    }, []);


    const [formData, setFormData] = useState({
        researchTitle: '',
        abstract: '',
        researchProposal: null,
        costing: null,
        hasCoApplicant: 'no',  // Track if the user has a co-applicant
        coApplicants: [], // Array to store co-applicant data
    });

    const navigate = useNavigate();

    const [errors, setErrors] = useState({
        researchTitle: '',
        abstract: '',
        researchProposal: '',
        costing: '',
        coApplicants: [], // Array to track co-applicant validation errors
    });


    const validateFile = (file, fieldName) => {
      if (!file) {
        setErrors((prev) => ({ ...prev, [fieldName]: 'File is required' }));
        return false;
      }
      if (!file.type.startsWith('application/pdf')) {
        setErrors((prev) => ({ ...prev, [fieldName]: 'Please upload a valid PDF file' }));
        return false;
      }
      setErrors((prev) => ({ ...prev, [fieldName]: '' })); // Clear errors if validation passes
      return true;
    };
     




    // Handle file change 
    const handleFileChange = (e, fieldName) => {
      const file = e.target.files[0];
      if (validateFile(file, fieldName)) {
        setFormData((prev) => ({ ...prev, [fieldName]: file })); // Update the state only when valid
      } else {
        setFormData((prev) => ({ ...prev, [fieldName]: null })); // Clear state if invalid
      }
    };

    // Handle file change for Costing
    // const handleFileChangeCosting = (e) => {
    //     const file = e.target.files[0];
    //     if (file && file.type === 'application/pdf') {
    //         setFormData({
    //             ...formData,
    //             costing: file
    //         });
    //         setErrors({
    //             ...errors,
    //             costing: ''
    //         });
    //     } else {
    //         setErrors({
    //             ...errors,
    //             costing: 'Please upload a valid PDF file'
    //         });
    //     }
    // };

    // Handle file change for Co-Applicant CV
    const handleFileChangeCoApplicantCV = (index, e) => {
        const file = e.target.files[0];
        const updatedCoApplicants = [...formData.coApplicants];
        if (file && file.type === 'application/pdf') {
            updatedCoApplicants[index].cv = file;
            setFormData({
                ...formData,
                coApplicants: updatedCoApplicants
            });
            setErrors({
                ...errors,
                coApplicants: errors.coApplicants.map((err, idx) => (idx === index ? '' : err))
            });
        } else {
            setErrors({
                ...errors,
                coApplicants: errors.coApplicants.map((err, idx) => (idx === index ? 'Please upload a valid PDF file' : err))
            });
        }
    };

    // Handle input changes for Co-Applicant fields
    const handleCoApplicantInputChange = (index, e) => {
        const { name, value } = e.target;
        const updatedCoApplicants = [...formData.coApplicants];
        updatedCoApplicants[index][name] = value;
        setFormData({
            ...formData,
            coApplicants: updatedCoApplicants
        });
    };

 
    // Add a new Co-Applicant
    const addCoApplicant = () => {
        if (formData.coApplicants.length < 2) {
            setFormData({
                ...formData,
                coApplicants: [...formData.coApplicants, { coApplicantsTitle: '', firstName: "", otherName: "", lastName: "", email: '', institutionName: '', cv: null }]
            });

            setErrors({
              ...errors,
              coApplicants: [
                  ...errors.coApplicants || [],
                  { coApplicantsTitle: null, firstName: null, otherName: null, lastName: null, email: null, institutionName: null, cv: null }
              ]
          });

        }
    };

    // Remove a Co-Applicant
    const removeCoApplicant = (index) => {
        const updatedCoApplicants = formData.coApplicants.filter((_, idx) => idx !== index);
        setFormData({
            ...formData,
            coApplicants: updatedCoApplicants
        });
    };

    // Handle text input changes
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
          ...formData,
          [name]: value,
      });
  };
  

    // Validate form
    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (formData.hasCoApplicant === 'yes' && formData.coApplicants.length <= 0 ){
         
            setPopup({
            message: `Submission Failed: You selected yes for co applicant, add your coapplicants `,
            type: "error",
            isVisible: true,
            buttonLabel: "Retry",
            buttonRoute: "../userdashboard/researchdetails",
        });
        isValid = false;
          
         }

        // Validate Research Title
        if (!formData.researchTitle) {
            formErrors.researchTitle = 'Research Title is required';
            isValid = false;
        }

        // Validate Abstract
        if (!formData.abstract) {
            formErrors.abstract = 'Abstract is required';
            isValid = false;
        }

        // Validate Research Proposal (must be a file)
        if (!formData.researchProposal) {
            formErrors.researchProposal = 'Research Proposal is required';
            isValid = false;
        }

        // Validate Costing (must be a file)
        if (!formData.costing) {
            formErrors.costing = 'Costing is required';
            isValid = false;
        }

        // Validate Co-Applicants (if any)
        if (formData.coApplicants.length > 0) {
            const coApplicantErrors = formData.coApplicants.map((coApplicant, index) => {
                const coApplicantError = {};

                if (!coApplicant.coApplicantsTitle) {
                    coApplicantError.coApplicantsTitle = 'Co-Applicant Title is required';
                    isValid = false;
                }
                if (!coApplicant.firstName) {
                  coApplicantError.firstName = "First Name is required";
                  isValid = false;
                }
                // if (!coApplicant.otherName) {
                //   coApplicantError.otherName = "Other Name is required";
                //   isValid = false;
                // }
                if (!coApplicant.lastName) {
                  coApplicantError.lastName = "Last Name/Surname is required";
                  isValid = false;
                }
                if (!coApplicant.email) {
                    coApplicantError.email = 'Co-Applicant Email is required';
                    isValid = false;
                }
                if (!coApplicant.institutionName) {
                    coApplicantError.institutionName = 'Co-Applicant Institution is required';
                    isValid = false;
                }
                if (!coApplicant.cv) {
                    coApplicantError.cv = 'Co-Applicant CV is required';
                    isValid = false;
                }

                return coApplicantError;
            });

            formErrors.coApplicants = coApplicantErrors;
        }

        setErrors(formErrors);
        return isValid;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      // Validate before submitting
      if (!validateForm()) {
          return; // Stop execution if form validation fails
      }


     
  
      setIsLoading(true);
  
      try {
          // Prepare main form data
          const formDataObj = new FormData();
          formDataObj.append("research_title", formData.researchTitle);
          formDataObj.append("abstract", formData.abstract);
  
          if (formData.researchProposal) {
              formDataObj.append("research_proposal", formData.researchProposal);
          }
  
          if (formData.costing) {
              formDataObj.append("costing", formData.costing);
          }
  
          // Submit research details
          const researchResponse = await fetch(`${process.env.REACT_APP_URL}/api/user/research`, {
              method: "POST",
              headers: {
                  Authorization: `Bearer ${token}`,
              },
              body: formDataObj,
          });
  
          const researchResult = await researchResponse.json();
  
          // Check if research submission succeeded
          if (!researchResponse.ok) {
              throw new Error(`Research submission failed: ${JSON.stringify(researchResult)}`);
          }
  
          console.log("Research submitted successfully:", researchResult);
  
          // Handle co-applicants only if applicable
          if (formData.hasCoApplicant && formData.coApplicants.length > 0) {
              for (const coApplicant of formData.coApplicants) {
                  const coApplicantData = new FormData();
                  coApplicantData.append("title_id", coApplicant.coApplicantsTitle);
                  coApplicantData.append("first_name", coApplicant.firstName || coApplicant.name);
                  coApplicantData.append("surname", coApplicant.lastName || "");
                  coApplicantData.append("other_name", coApplicant.otherName || "");
                  coApplicantData.append("email", coApplicant.email);
                  coApplicantData.append("inst_id", coApplicant.institutionName);
                  coApplicantData.append("cv", coApplicant.cv);
  
                  const coApplicantResponse = await fetch(
                      `${process.env.REACT_APP_URL}/api/user/co-applicant`,
                      {
                          method: "POST",
                          headers: {
                              Authorization: `Bearer ${token}`,
                          },
                          body: coApplicantData,
                      }
                  );
  
                  const coApplicantResult = await coApplicantResponse.json();
  
                  if (!coApplicantResponse.ok) {
                      throw new Error(
                          `Co-applicant submission failed for ${coApplicant.email}: ${JSON.stringify(
                              coApplicantResult
                          )}`
                      );
                  }
  
                  console.log(`Co-applicant ${coApplicant.email} submitted successfully.`);
              }
          }
  
          // All submissions were successful
          setPopup({
            message: "Your Research Grant Application has Been Recieved!",
            type: "success",
            isVisible: true,
            buttonLabel: "Proceed to your Dashboard",
            buttonRoute: "/userdashboard",
        });
          
      } catch (error) {
          console.error("Submission failed:", error);
  
          // Error message for popup
          const errorMessage = error?.message || "An unknown error occurred. Please try again.";
          setPopup({
              message: `Submission Failed: ${errorMessage}`,
              type: "error",
              isVisible: true,
              buttonLabel: "Retry",
              buttonRoute: "/userdashboard/researchdetails",
          });
      } finally {
          setIsLoading(false);
      }
  };
  
    const handleLogout = () => {
      localStorage.removeItem('authToken');
      window.location.href = '/login'; // Redirect to login page
  };
  

    // Inline CSS styles
    const styles = {
        input: {
          width: "100%",
          padding: "10px",
          marginTop: "5px",
          borderRadius: "4px",
          border: "1px solid #ccc",
        },
        button: {
          width: "48%",
          padding: "10px",
          borderRadius: "4px",
          cursor: "pointer",
          fontSize: "16px",
          marginTop: "10px",
        },
        prevButton: {
          backgroundColor: "#ec3237",
          color: "#fff",
          border: "none",
        },
        nextButton: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          border: "none",
        },
        formRow: {
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        },
        formColumn: {
          width: "48%",
          marginRight: "2%",
        },
        formColumnLast: {
          width: "48%",
          marginRight: "0",
        },
        error: {
          color: "red",
          fontSize: "12px",
          marginTop: "5px",
        },
        buttonContainer: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
        },
        textCenter: {
          fontSize: "18px",
          fontWeight: "bold",
        },
      };

  // Inline styles2
  const styles2 = {
    dashboardContainer: {
      display: "flex",
      minHeight: "100vh",
    },
    sideMenuContainer: {
      flex: 1,
      padding: "20px",
      backgroundColor: "#f9f9f9",
      borderRight: "1px solid #ddd",
    },
    contentContainer: {
      flex: 3,
      padding: "20px",
      backgroundColor: "#fff",
    },
    sideMenu: {
      marginBottom: "20px",
    },
    heading: {
      marginBottom: "10px",
      color: "#333",
    },
    status: {
      fontSize: "14px",
      marginBottom: "20px",
    },
    statusOpen: {
      color: "green",
      fontWeight: "bold",
    },
    menuButton: {
      backgroundColor: "#ec3237",
      color: "white",
      border: "none",
      borderRadius: "5px",
      width: "80%",
      marginBottom: "10px",
      padding: "10px",
      cursor: "pointer",
      display: "flex", // Aligns icon and text horizontally
      alignItems: "center",
    },
    disabledButton: {
      backgroundColor: "#cccccc", // Lighter background for disabled buttons
      color: "#777", // Lighter text for disabled buttons
      cursor: "not-allowed", // Show not-allowed cursor when disabled
    },
    completedIcon: {
      marginRight: "2px", // Add space between the icon and text
      width: "16px",
      height: "16px",
    },
    applicationList: {
      marginBottom: "20px",
    },
    applicationListItem: {
      marginBottom: "10px",
    },
    applicationLink: {
      textDecoration: "none",
      color: "#0066cc",
    },
    applicationLinkHover: {
      textDecoration: "underline",
    },
    primaryButton: {
      backgroundColor: "#1db540",
      color: "white",
      padding: "10px 20px",
      border: "none",
      borderRadius: "5px",
      marginTop: "20px",
      cursor: "pointer",
    },
  };

   const SideMenu = () => {
          const menuItems = [
            { text: "Home", path: "/userdashboard", isCompleted: false },
            { text: "Personal Details", path: "/userdashboard/personaldetails", isCompleted: personalDetailsCompleted },
            { text: "Institution Details", path: "/userdashboard/institutiondetails", isCompleted: institutionDetailsCompleted },
            { text: "Research Details", path: "/userdashboard/researchdetails", isCompleted: researchDetailsCompleted }
          ];
      
          return (
            <div style={{
              ...styles2.sideMenuContainer,
              display: isMobile && !isMenuOpen ? "none" : "block", // Hide on mobile unless menu is open
            }}>
              <h2 style={styles2.heading}>Get Started</h2>
              <p style={styles2.status}>
                <strong>
                  Application Status: <span style={styles2.statusOpen}>Open</span>
                </strong>
              </p>
              {menuItems.map((menu, index) => (
                <button
                  key={index}
                  style={{
                    ...styles2.menuButton,
                    ...(menu.isCompleted && styles2.disabledButton),
                  }}
                  onClick={() => handleMenuClick(menu.path)}
                  disabled={menu.isCompleted}
                >
                  {menu.isCompleted && <img src={checked} alt="Completed" style={styles2.completedIcon} />} {/* Show checked image */}
                  {menu.text}
                </button>
              ))}
              <button style={styles2.menuButton} onClick={() => handleLogout()}>
                Log Out
              </button>
            </div>
          );
        };
  
 
  
    return (
      <div style={styles2.dashboardContainer}>
         {/* Hamburger Button for mobile */}
      {isMobile && (
        <button
          style={{
            ...styles.hamburgerButton,
            position: isMenuOpen ? "fixed" : "absolute", // Fix button at top when menu is open
            top: isMenuOpen ? "10px" : "20px", // Adjust position when the menu is open
            right: "20px", // Position at the top right
            zIndex: 1000, // Ensure it's always above other elements
          }}
          onClick={toggleMenu}
        >
          {isMenuOpen ? "X" : "☰"}
        </button>
      )}

       {/* Side menu for mobile screens */}
       {isMobile && isMenuOpen && <SideMenu />}

        {!isMobile && <SideMenu />}
        <div style={styles2.contentContainer}>
        <div style={styles2.applicationList}>
        <form onSubmit={handleSubmit}>
                          <div className="login-top">
                            <h3>Research Title</h3>
                          </div>
  
                          {/* Research Title */}
                          <div className="form-group">
                            <label>Research Title</label>
                            <input
                              placeholder="Research Title"
                              type="text"
                              id="researchTitle"
                              name="researchTitle"
                              value={formData.researchTitle}
                              onChange={handleInputChange}
                              style={styles.input}
                            />
                            {errors.researchTitle && (
                              <span className="error" style={styles.error}>
                                {errors.researchTitle}
                              </span>
                            )}
                          </div>
  
                          {/* Abstract */}
                          <div className="form-group">
                            <label>Abstract</label>
                            <input
                              placeholder="Abstract"
                              type="text"
                              id="abstract"
                              name="abstract"
                              value={formData.abstract}
                              onChange={handleInputChange}
                              style={styles.input}
                            />
                            {errors.abstract && (
                              <span className="error" style={styles.error}>
                                {errors.abstract}
                              </span>
                            )}
                          </div>
  
                          {/* Research Proposal Upload */}
                          <div className="form-group">
                            <label>Upload Research Proposal</label>
                            <input
                              type="file"
                              id="researchProposal"
                              name="researchProposal"
                              accept="application/pdf"
                              onChange={(e) => handleFileChange(e, 'researchProposal')} 
                              style={styles.input}
                            />
                            {errors.researchProposal && (
                              <span className="error" style={styles.error}>
                                {errors.researchProposal}
                              </span>
                            )}
                          </div>
  
                          {/* Costing Upload */}
                          <div className="form-group">
                            <label>Upload Costing</label>
                            <input
                              type="file"
                              id="costing"
                              name="costing"
                              accept="application/pdf"
                              onChange={(e) => handleFileChange(e, 'costing')}
                              style={styles.input}
                            />
                            {errors.costing && (
                              <span className="error" style={styles.error}>
                                {errors.costing}
                              </span>
                            )}
                          </div>
  
                          {/* Do you have a Co-applicant? */}
                          <div className="form-group">
                                      <label>Do you have a Co-applicant?</label>
                                      <select
                                          name="hasCoApplicant"
                                          value={formData.hasCoApplicant}
                                          onChange={handleInputChange}
                                          style={styles.input}
                                      >
                                          <option value="no">No</option>
                                          <option value="yes">Yes</option>
                                      </select>
                                  </div>
  
                                  {/* Co-applicant Details (if applicable) */}
                                  {formData.hasCoApplicant === 'yes' && (
                                      <div>
                                          <h4>Add Co-Applicant(s)</h4>
  
                                          {/* Loop through co-applicants */}
                                          {formData.coApplicants.map((coApplicant, index) => (
                                              <div key={index} className="co-applicant">
                                                  <div className="form-row" style={styles.formRow}>
                                                      <div className="form-column" style={styles.formColumn}>
                                                          <label>Co-Applicant Title</label>
                                                          <select
                                              name="coApplicantsTitle"
                                              id='coApplicantsTitle'
                                              value={coApplicant.coApplicantsTitle}
                                              onChange={(e) => handleCoApplicantInputChange(index, e)}
                                              style={styles.select}
                                          >
                                              <option value="">Select Title</option>
              {coApplicantsTitle.map((name, id, index) => (
                <option key={index} value={id+1}>
                  {name}
                </option>
                 ))}
                                          </select>
                                                          {errors.coApplicants[index]?.coApplicantsTitle && <span style={styles.error}>{errors.coApplicants[index].coApplicantsTitle}</span>}
                                                      </div>
  
                                                      
                                                  </div>
  
                                                  <div className="form-row" style={styles.formRow}>
                                                  <div className="form-column" style={styles.formColumn}>
                                                          <label>Co-Applicant Name</label>
                                                          <input
                                                              type="text"
                                                              name="firstName"
                                                              value={coApplicant.firstName}
                                                              onChange={(e) => handleCoApplicantInputChange(index, e)}
                                                              style={styles.input}
                                                          />
                                                          {errors.coApplicants[index]?.firstName && <span style={styles.error}>{errors.coApplicants[index].firstName}</span>}
                                                      </div>
                                                      <div className="form-column" style={styles.formColumn}>
                                                          <label> Co-applicant Other Name</label>
                                                          <input
                                                              type="text"
                                                              name="otherName"
                                                              value={coApplicant.otherName}
                                                              onChange={(e) => handleCoApplicantInputChange(index, e)}
                                                              style={styles.input}
                                                          />
                                                          {errors.coApplicants[index]?.otherName && <span style={styles.error}>{errors.coApplicants[index].otherName}</span>}
                                                      </div>

                                                      <div className="form-column" style={styles.formColumn}>
                                                          <label>Co-Applicant Surname</label>
                                                          <input
                                                              type="text"
                                                              name="lastName"
                                                              value={coApplicant.lastName}
                                                              onChange={(e) => handleCoApplicantInputChange(index, e)}
                                                              style={styles.input}
                                                          />
                                                          {errors.coApplicants[index]?.lastName && <span style={styles.error}>{errors.coApplicants[index].lastName}</span>}
                                                      </div>
                                                      <div className="form-column" style={styles.formColumn}>
                                                          <label>Co-Applicant Email</label>
                                                          <input
                                                              type="email"
                                                              name="email"
                                                              value={coApplicant.email}
                                                              onChange={(e) => handleCoApplicantInputChange(index, e)}
                                                              style={styles.input}
                                                          />
                                                          {errors.coApplicants[index]?.email && <span style={styles.error}>{errors.coApplicants[index].email}</span>}
                                                      </div>
  
                                                  </div>
  
                                              
                                                  <div className="form-row" style={styles.formRow}>
                                                  <div className="form-column" style={styles.formColumnLast}>
                                                          <label>Co-Applicant Institution</label>
                                                          <select
                                        id="institutionName"
                                        name="institutionName"
                                        value={coApplicant.institutionName}
                                        onChange={(e) => handleCoApplicantInputChange(index, e)}
                                        style={{ width: '100%', padding: '10px', borderRadius: '4px' }}
                                    >
                                        <option value="" >Select Institution</option>
                                        {institutionNames.map((name, id, index) => (
                                            <option key={index} value={id+1}>
                                                {name}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.coApplicants[index]?.institutionName && <span style={styles.error}>{errors.coApplicants[index].institutionName}</span>}

                                                      </div>
                                                      <div className="form-column" style={styles.formColumn}>
                                                          <label>Upload Co-Applicant CV</label>
                                                          <input
                                                              type="file"
                                                              accept="application/pdf"
                                                              onChange={(e) => handleFileChangeCoApplicantCV(index, e)}
                                                              style={styles.input}
                                                          />
                                                          {errors.coApplicants[index]?.cv && <span style={styles.error}>{errors.coApplicants[index].cv}</span>}
                                                      </div>
                                                  </div>
  
                                                  {/* Remove Co-Applicant Button */}
                                                  <button
                                                      type="button"
                                                      onClick={() => removeCoApplicant(index)}
                                                      style={styles.button}
                                                  >
                                                      Remove Co-Applicant
                                                  </button>
                                              </div>
                                          ))}
  
                                          {/* Add Co-Applicant Button */}
                                          {formData.coApplicants.length < 2 && (
                                              <button
                                                  type="button"
                                                  onClick={addCoApplicant}
                                                  style={styles.button}
                                              >
                                                  Add Co-Applicant
                                              </button>
                                          )}
                                      </div>
                                  )}
  
                          {/* Button Container */}
                          <div style={styles.buttonContainer}>
                            <button
                              type="button"
                              style={{ ...styles.button, ...styles.prevButton }}
                              onClick={() =>
                                navigate("/userdashboard/personaldetails")
                              }
                            >
                              Prev
                            </button>
  
                            <div style={styles.textCenter}>3/3</div>
  
                            <button
                              type="submit"
                              style={{ ...styles.button, ...styles.nextButton }}
                              disabled={isLoading}
 
                            >
                                                {isLoading ? 'Submiting...' : 'Submit'}

                            </button>
                          </div>
                        </form>
      </div>
        </div>
        {popup.isVisible && (
                <Popup
                    message={popup.message}
                    type={popup.type}
                    onClose={() => setPopup({ ...popup, isVisible: false })}
                    buttonLabel={popup.buttonLabel}
                    buttonRoute={popup.buttonRoute}
                />
            )}
      </div>
    );
  };
  
  export default ResearchDetailsMain;
