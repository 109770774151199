import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import checked from '../../assets/svgs/check_svg.svg'


import Popup from '../Popup/Popup';

const InstitutionDetailsMain = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
    const [isLoading, setIsLoading] = useState(false);
      const [personalDetailsCompleted, setPersonalDetailsCompleted] = useState(false);
        const [institutionDetailsCompleted, setInstitutionDetailsCompleted] = useState(false);
        const [researchDetailsCompleted, setResearchDetailsCompleted] = useState(false);
        const [isMenuOpen, setIsMenuOpen] = useState(false);


      const [popup, setPopup] = useState({ message: "", type: "", isVisible: false, buttonLabel: "", buttonRoute: "" });
      const token = localStorage.getItem('authToken');

         useEffect(() => {
            const checkDetails = async () => {
              try {
                  
                // If the token is available, include it in the headers
                const headers = token ? { Authorization: `Bearer ${token}` } : {};
          
                // Fetch data for personal, institution, and research checks with Authorization headers
                const personalRes = await fetch(`${process.env.REACT_APP_URL}/api/user/personal-check`, { headers });
                const institutionRes = await fetch(`${process.env.REACT_APP_URL}/api/user/institution-check`, { headers });
                const researchRes = await fetch(`${process.env.REACT_APP_URL}/api/user/research-check`, { headers });
                // Parse JSON responses
                const personalData = await personalRes.json();
                console.log('this is personal check', personalDetailsCompleted)
      
                const institutionData = await institutionRes.json();
                const researchData = await researchRes.json();
          
                // Check if the response has 'data' field and set accordingly
                setPersonalDetailsCompleted(personalData.data);
                setInstitutionDetailsCompleted(institutionData.data);  // Assuming institution check returns a similar response format
                setResearchDetailsCompleted(researchData.data); // Assuming research check returns a similar response format
              } catch (error) {
                console.error("Error fetching details", error);
                const errorMessage = error?.message || "An unknown error occurred. Please try again.";
        
              //   setPopup({
              //     message: `error occured: ${errorMessage}`,
              //     type: "error",
              //     isVisible: true,
              //     buttonLabel: "Retry",
              //     buttonRoute: "/userdashboard/institutiondetails",
              // });
              }
            };
          
            checkDetails();
          }, []);
          
      
          const handleMenuClick = (path) => {
            if (path === "/userdashboard/researchdetails" && !personalDetailsCompleted) {
              setPopup({
                    message: `Please fill out your Personal Details first.`,
                    type: "error",
                    isVisible: true,
                    buttonLabel: "Proceed to Personal Details",
                    buttonRoute: "/userdashboard/personaldetails",
                  });      }
                  else if (path === "/userdashboard/researchdetails" && !institutionDetailsCompleted) {
          setPopup({
                message: `Please fill out your Institution Details first.`,
                type: "error",
                isVisible: true,
                buttonLabel: "Proceed to Institution Details",
                buttonRoute: "/userdashboard/institutiondetails",
              });      } else if (path === "/userdashboard/institutiondetails" && !personalDetailsCompleted) {
              setPopup({
                message: `Please fill out your Personal Details first.`,
                type: "error",
                isVisible: true,
                buttonLabel: "Proceed to Personal Details",
                buttonRoute: "/userdashboard/personaldetails",
              });  
            } else {
              navigate(path);
            }
          };
        
          const toggleMenu = () => {
            setIsMenuOpen(!isMenuOpen);
          };
      

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 600);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    const [formData, setFormData] = useState({
        institutionName: '',
        faculties: '',
        institutionDept: '',
        cv: null,
        supportLetter: null,
    });

    const [institutionNames, setInstitutionNames] = useState([]);
    const [faculties, setFaculties] = useState([]);
    const navigate = useNavigate();

    const [errors, setErrors] = useState({
        institutionName: '',
        institutionDept: '',
        faculties: '',
        cv: '',
        supportLetter: '',
    });

    // Fetch institution names
    useEffect(() => {
        const fetchInstitutionNames = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_URL}/api/allschools`);
                const names = response.data.map(item => item.inst);
                setInstitutionNames(names);
            } catch (error) {
                console.error('Error fetching institution names:', error);
            }
        };

        fetchInstitutionNames();
    }, []);

    // Fetch faculties whenever an institution is selected
    useEffect(() => {
            const fetchFaculties = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_URL}/api/allfaculties`);
                    const facultyNames = response.data.map(item => item.faculty); // Adjust based on API response
                    setFaculties(facultyNames);
                } catch (error) {
                    console.error('Error fetching faculties:', error);
                }
            };

            fetchFaculties();
        }, []);

        const validateFile = (file, fieldName) => {
          if (!file) {
            setErrors((prev) => ({ ...prev, [fieldName]: 'File is required' }));
            return false;
          }
          if (!file.type.startsWith('application/pdf')) {
            setErrors((prev) => ({ ...prev, [fieldName]: 'Please upload a valid PDF file' }));
            return false;
          }
          setErrors((prev) => ({ ...prev, [fieldName]: '' })); // Clear errors if validation passes
          return true;
        };
    
    const handleFileChange = (e, fieldName) => {
      const file = e.target.files[0];
      if (validateFile(file, fieldName)) {
        setFormData((prev) => ({ ...prev, [fieldName]: file })); // Update the state only when valid
      } else {
        setFormData((prev) => ({ ...prev, [fieldName]: null })); // Clear state if invalid
      }
    };
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!formData.institutionName) {
            formErrors.institutionName = 'Institution Name is required';
            isValid = false;
        }
        if (!formData.faculties) {
          formErrors.faculties = 'Faculty is required';
          isValid = false;
      }
        if (!formData.institutionDept) {
            formErrors.institutionDept = 'Department is required';
            isValid = false;
        }

        if (!formData.cv) {
            formErrors.cv = 'CV is required';
            isValid = false;
        }

       
        if (!formData.supportLetter) {
          formErrors.supportLetter = 'Instituion Support letter is required';
          isValid = false;
      } 

        

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
          setIsLoading(true);

          try {
            // Prepare the form data
            const formDataObj = new FormData();
            formDataObj.append("inst_id", formData.institutionName);
            formDataObj.append("faculty_id", formData.faculties);
            formDataObj.append("department", formData.institutionDept);
            if (formData.cv) {
                formDataObj.append("cv", formData.cv); // Add the pdf file
            }

            if (formData.supportLetter) {
              formDataObj.append("inst_sup_letter", formData.supportLetter); // Add the pdf file
          }
      
        console.log("this is the data", formDataObj)
        
            const response = await fetch(`${process.env.REACT_APP_URL}/api/user/institution`, {
                method: 'POST',
                headers: {
                    // 'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`  },
                body: formDataObj,
            });
      
            const result = await response.json();
      
            console.log("result", result)
            console.log(typeof(formData.cv))
      
            if (response.ok) {
                console.log('Institution Details Submitted successful:', result);
                // Save the token to localStorage
                // localStorage.setItem('authToken', result.token);
      
                setPopup({
                  message: "Institution Details Submitted Successfully!",
                  type: "success",
                  isVisible: true,
                  buttonLabel: "Proceed to Enter Your Research Details",
                  buttonRoute: "/userdashboard/researchdetails",
              });
            } else {
                console.error('Submission failed:', result);
                const errorMessages = Object.entries(result)
                .map(([key, messages]) => `${key}: ${messages.join(', ')}`)
                .join('\n');
                setPopup({
                    message: `Submission Failed: ${errorMessages}`,
                    type: "error",
                    isVisible: true,
                    buttonLabel: "Retry",
                    buttonRoute: "/userdashboard/institutiondetails",
                });
            }
        } catch (error) {
            console.error('Error during Login:', error);
            setPopup({
                message: "An error occurred. Please try again.",
                type: "error",
                isVisible: true,
                buttonLabel: "Retry",
                buttonRoute: "/userdashboard/institutiondetails",
            });
        } finally {
            setIsLoading(false);
        }
          }
        };

    const handleLogout = () => {
      localStorage.removeItem('authToken');
      window.location.href = '/login'; // Redirect to login page
  };
  

    // Inline CSS styles
    const styles = {
        input: {
          width: "100%",
          padding: "10px",
          marginTop: "5px",
          borderRadius: "4px",
          border: "1px solid #ccc",
        },
        button: {
          width: "48%",
          padding: "10px",
          borderRadius: "4px",
          cursor: "pointer",
          fontSize: "16px",
          marginTop: "10px",
        },
        prevButton: {
          backgroundColor: "#ec3237",
          color: "#fff",
          border: "none",
        },
        nextButton: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          border: "none",
        },
        formRow: {
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        },
        formColumn: {
          width: "48%",
          marginRight: "2%",
        },
        formColumnLast: {
          width: "48%",
          marginRight: "0",
        },
        error: {
          color: "red",
          fontSize: "12px",
          marginTop: "5px",
        },
        buttonContainer: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
        },
        textCenter: {
          fontSize: "18px",
          fontWeight: "bold",
        },
      };
    
      // Inline styles2
      const styles2 = {
        dashboardContainer: {
          display: "flex",
          minHeight: "100vh",
        },
        sideMenuContainer: {
          flex: 1,
          padding: "20px",
          backgroundColor: "#f9f9f9",
          borderRight: "1px solid #ddd",
        },
        contentContainer: {
          flex: 3,
          padding: "20px",
          backgroundColor: "#fff",
        },
        sideMenu: {
          marginBottom: "20px",
        },
        heading: {
          marginBottom: "10px",
          color: "#333",
        },
        status: {
          fontSize: "14px",
          marginBottom: "20px",
        },
        statusOpen: {
          color: "green",
          fontWeight: "bold",
        },
        menuButton: {
          backgroundColor: "#ec3237",
          color: "white",
          border: "none",
          borderRadius: "5px",
          width: "80%",
          marginBottom: "10px",
          padding: "10px",
          cursor: "pointer",
          display: "flex", // Aligns icon and text horizontally
          alignItems: "center",
        },
        disabledButton: {
          backgroundColor: "#cccccc", // Lighter background for disabled buttons
          color: "#777", // Lighter text for disabled buttons
          cursor: "not-allowed", // Show not-allowed cursor when disabled
        },
        completedIcon: {
          marginRight: "2px", // Add space between the icon and text
          width: "16px",
          height: "16px",
        },
        applicationList: {
          marginBottom: "20px",
        },
        applicationListItem: {
          marginBottom: "10px",
        },
        applicationLink: {
          textDecoration: "none",
          color: "#0066cc",
        },
        applicationLinkHover: {
          textDecoration: "underline",
        },
        primaryButton: {
          backgroundColor: "#1db540",
          color: "white",
          padding: "10px 20px",
          border: "none",
          borderRadius: "5px",
          marginTop: "20px",
          cursor: "pointer",
        },
      };

      const SideMenu = () => {
        const menuItems = [
          { text: "Home", path: "/userdashboard", isCompleted: false },
          { text: "Personal Details", path: "/userdashboard/personaldetails", isCompleted: personalDetailsCompleted },
          { text: "Institution Details", path: "/userdashboard/institutiondetails", isCompleted: institutionDetailsCompleted },
          { text: "Research Details", path: "/userdashboard/researchdetails", isCompleted: researchDetailsCompleted }
        ];
    
        return (
          <div style={{
            ...styles2.sideMenuContainer,
            display: isMobile && !isMenuOpen ? "none" : "block", // Hide on mobile unless menu is open
          }}>
            <h2 style={styles2.heading}>Get Started</h2>
            <p style={styles2.status}>
              <strong>
                Application Status: <span style={styles2.statusOpen}>Open</span>
              </strong>
            </p>
            {menuItems.map((menu, index) => (
              <button
                key={index}
                style={{
                  ...styles2.menuButton,
                  ...(menu.isCompleted && styles2.disabledButton),
                }}
                onClick={() => handleMenuClick(menu.path)}
                disabled={menu.isCompleted}
              >
                {menu.isCompleted && <img src={checked} alt="Completed" style={styles2.completedIcon} />} {/* Show checked image */}
                {menu.text}
              </button>
            ))}
            <button style={styles2.menuButton} onClick={() => handleLogout()}>
              Log Out
            </button>
          </div>
        );
      };
    
      
      
       
      
        return (
          <div style={styles2.dashboardContainer}>
               {/* Hamburger Button for mobile */}
      {isMobile && (
        <button
          style={{
            ...styles.hamburgerButton,
            position: isMenuOpen ? "fixed" : "absolute", // Fix button at top when menu is open
            top: isMenuOpen ? "10px" : "20px", // Adjust position when the menu is open
            right: "20px", // Position at the top right
            zIndex: 1000, // Ensure it's always above other elements
          }}
          onClick={toggleMenu}
        >
          {isMenuOpen ? "X" : "☰"}
        </button>
      )}

       {/* Side menu for mobile screens */}
       {isMobile && isMenuOpen && <SideMenu />}


            {!isMobile && <SideMenu />}
            <div style={styles2.contentContainer}>
            <div style={styles2.applicationList}>
            <form onSubmit={handleSubmit}>
              <div className="login-top">
                <h3>Institution Details</h3>
              </div>
      
              {/* Institution Name */}
              <div className="form-group">
                <label>Institution Name</label>
                <select
                                        id="institutionName"
                                        name="institutionName"
                                        value={formData.institutionName}
                                        onChange={handleInputChange}
                                        style={{ width: '100%', padding: '10px', borderRadius: '4px' }}
                                    >
                                        <option value="" >Select Institution</option>
                                        {institutionNames.map((name, id, index) => (
                                            <option key={index} value={id+1}>
                                                {name}
                                            </option>
                                        ))}
                                    </select>
                {errors.institutionName && (
                  <span className="error" style={styles.error}>
                    {errors.institutionName}
                  </span>
                )}
              </div>
      
              {/* faculty */}
              <div className="form-group">
                <label>Faculty </label>
                <select
                                        id="falculties"
                                        name="faculties"
                                        value={formData.faculties}
                                        onChange={handleInputChange}
                                        style={{ width: '100%', padding: '10px', 
                                        borderRadius: '4px' }}
                                    >
                                        <option value="">Select Faculty</option>
                                        {faculties.map((faculty, id, index) => (
                                            <option key={index} value={id+1}>
                                                {faculty}
                                            </option>
                                        ))}
                                    </select>
                {errors.faculties && (
                  <span className="error" style={styles.error}>
                    {errors.faculties}
                  </span>
                )}
              </div>

               {/* Department */}
               <div className="form-group">
                <label>Department</label>
                <input
                                        id="institutionDept"
                                        name="institutionDept"
                                        type='text'
                                        value={formData.institutionDept}
                                        onChange={handleInputChange}
                                        style={{ width: '100%', padding: '10px', 
                                        borderRadius: '4px' }}
                                    />
  
                {errors.institutionDept && (
                  <span className="error" style={styles.error}>
                    {errors.institutionDept}
                  </span>
                )}
              </div>
      
              {/* Upload CV */}
              <div className="form-group">
                <label>Upload Academic CV: </label>
                <input
                type="file"
  accept="application/pdf"
  onChange={(e) => handleFileChange(e, 'cv')} // Required field
  style={styles.input}
/>
                {errors.cv && (
                  <span className="error" style={styles.error}>
                    {errors.cv}
                  </span>
                )}
              </div>

              <div className="form-group">
               {/* Support Letter (optional field) */}
<label> Upload Letter from Institution Recommending You for Grant:</label>
<input
  type="file"
  accept="application/pdf"
  onChange={(e) => handleFileChange(e, 'supportLetter')} // Optional field
  style={styles.input} />
                {errors.supportLetter && (
                  <span className="error" style={styles.error}>
                    {errors.supportLetter}
                  </span>
                )}
              </div>
      
              {/* Button Container */}
              <div style={styles.buttonContainer}>
                <button
                  type="button"
                  style={{ ...styles.button, ...styles.prevButton }}
                  onClick={() => navigate("/userdashboard/researchdetails")}
                >
                  Prev
                </button>
      
                <div style={styles.textCenter}>2/3</div>
      
                <button
                  type="submit"
                  style={{ ...styles.button, ...styles.nextButton }}
                  disabled={isLoading}
                >
                  {isLoading ? 'Submiting...' : 'Next'}
                </button>
              </div>
            </form>

            {popup.isVisible && (
                <Popup
                    message={popup.message}
                    type={popup.type}
                    onClose={() => setPopup({ ...popup, isVisible: false })}
                    buttonLabel={popup.buttonLabel}
                    buttonRoute={popup.buttonRoute}
                />
            )}
          </div>
            </div>
          </div>
        );
      };
      
      export default InstitutionDetailsMain;

