import React, { useState } from 'react';
import Popup from '../../components/Popup/Popup'


const token = localStorage.getItem('authToken');

const ContactForm = (props) => {
    const [statusMessage, setStatusMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [popup, setPopup] = useState({ message: "", type: "", isVisible: false, buttonLabel: "", buttonRoute: "" });

     const [formData, setFormData] = useState({
            email: '',
            name: '',
            message: '',
            subject: '',
            phone: '',
        });
    
        const [errors, setErrors] = useState({
            name: '',
            email: '',
            message: '',
            subject: '',
            phone: '',
        });
        
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!formData.name) {
            formErrors.name = 'name is required';
            isValid = false;
        }

        // Email Validation
        if (!formData.email) {
            formErrors.email = 'Email is required';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = 'Email is invalid';
            isValid = false;
        }

        if (!formData.subject) {
            formErrors.subject = 'Subject is required';
            isValid = false;
        }

        if (!formData.phone) {
            formErrors.phone = 'Phone is required';
            isValid = false;
        }


        // Password Validation
        if (!formData.message) {
            formErrors.message = 'Message is required';
            isValid = false;
        } 

        setErrors(formErrors);
        return isValid;
    };

    // Send email via POST request to '/contact-us'
    const sendEmail = async (e) => {
        e.preventDefault();

  // Validate form data before submitting
  if (validateForm()) {
    setIsLoading(true);
    const data = {
        name: formData.name,
        email: formData.email,                
        message: formData.message,
        subject: formData.subject,
        phone: formData.phone,

    };

    try {
        const response = await fetch(`${process.env.REACT_APP_URL}/api/contact-us`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        const result = await response.json();
        console.log(response)

        if (response.ok) {
            console.log('Message sent successful:', result);
            // Save the token to localStorage
            localStorage.setItem('authToken', result.token);

            setPopup({
                message: "Message sent successful!",
                type: "success",
                isVisible: true,
                buttonLabel: "close",
                buttonRoute: "/contact",
            });
        } else {
            console.error('Message Sending failed:', result);
            const errorMessages = result.message
            setPopup({
                message: `Message Sending Failed: ${errorMessages}`,
                type: "error",
                isVisible: true,
                buttonLabel: "Retry",
                buttonRoute: "/contact",
            });
        }
    } catch (error) {
        console.error('Error sending Message:', error);
        setPopup({
            message: "An error occurred. Please try again.",
            type: "error",
            isVisible: true,
            buttonLabel: "Retry",
            buttonRoute: "/contact",
        });
    } finally {
        setIsLoading(false);
    }
}


     

       

    };

    return (
        <div className="react-blog-form">
            <h2 className="contact-title">Questions? <br /> Feel free to contact us.</h2>
            <div id="blog-form" className="blog-form">
                <div id="form-messages">
                    {statusMessage && <p>{statusMessage}</p>}
                </div>
                <form id="contact-form" onSubmit={sendEmail}>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="back-input">
                                <input id="name" type="text" name="name" required placeholder="Name" onChange={handleInputChange}  />
                                {errors.name && <span className="error"  style={{color: "red"}}>{errors.name}</span>}
                            </div>

                        </div>

                        <div className="col-lg-6 pdl-5">
                            <div className="back-input">
                                <input id="email" type="email" name="email" required placeholder="Email" onChange={handleInputChange} />
                                {errors.email && <span className="error"  style={{color: "red"}}>{errors.email}</span>}
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="back-input">
                                <input id="subject" type="text" name="subject" required placeholder="Subject" onChange={handleInputChange} />
                                {errors.subject && <span className="error"  style={{color: "red"}}>{errors.subject}</span>}
                            </div>
                        </div>

                        <div className="col-lg-6 pdl-5">
                            <div className="back-input">
                                <input id="phone" type="text" name="phone" required placeholder="Phone" onChange={handleInputChange} />
                                {errors.phone && <span className="error"  style={{color: "red"}}>{errors.phone}</span>}
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="back-textarea">
                                <textarea id="message" name="message" required placeholder="Message" onChange={handleInputChange}></textarea>
                                {errors.message && <span className="error"  style={{color: "red"}}>{errors.message}</span>}
                            </div>
                        </div>

                        <div className="col-lg-12">
                        <button type="submit" id="button" name="submit" disabled={isLoading}>
                        {isLoading ? 'Sending...' : 'Message'}
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right">
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                    <polyline points="12 5 19 12 12 19"></polyline>
                                </svg>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {popup.isVisible && (
                <Popup
                    message={popup.message}
                    type={popup.type}
                    onClose={() => setPopup({ ...popup, isVisible: false })}
                    buttonLabel={popup.buttonLabel}
                    buttonRoute={popup.buttonRoute}
                />
            )}
        </div>
    );
};

export default ContactForm;
