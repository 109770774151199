import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Popup from '../Popup/Popup';

const ApplicationDetail = () => {
  const navigate = useNavigate();
  const [application, setApplication] = useState(null);
  const [personalDetailsCompleted, setPersonalDetailsCompleted] = useState(false);
  const [institutionDetailsCompleted, setInstitutionDetailsCompleted] = useState(false);
  const [researchDetailsCompleted, setResearchDetailsCompleted] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDeatails, setIsLoadingDetails] = useState(false);

  const [popup, setPopup] = useState({
    message: '',
    type: '',
    isVisible: false,
    buttonLabel: '',
    buttonRoute: '',
  });

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  const token = localStorage.getItem('authToken');

  const styles2 = {
    status: {
      color: "red",
    },
  };
   useEffect(() => {
        const checkDetails = async () => {
          setIsLoadingDetails(true);
          try {
            // If the token is available, include it in the headers
            const headers = token ? { Authorization: `Bearer ${token}` } : {};
      
            // Fetch data for personal, institution, and research checks with Authorization headers
            const personalRes = await fetch(`${process.env.REACT_APP_URL}/api/user/personal-check`, { headers });
            const institutionRes = await fetch(`${process.env.REACT_APP_URL}/api/user/institution-check`, { headers });
            const researchRes = await fetch(`${process.env.REACT_APP_URL}/api/user/research-check`, { headers });
            // Parse JSON responses
            const personalData = await personalRes.json();
            console.log('this is personal check', personalDetailsCompleted)
  
            const institutionData = await institutionRes.json();
            const researchData = await researchRes.json();
      
            // Check if the response has 'data' field and set accordingly
            setPersonalDetailsCompleted(personalData.data);
            setInstitutionDetailsCompleted(institutionData.data);  // Assuming institution check returns a similar response format
            setResearchDetailsCompleted(researchData.data); // Assuming research check returns a similar response format
          } catch (error) {
            console.error("Error fetching details", error);
            const errorMessage = error?.message || "An unknown error occurred. Please try again.";
    
          //   setPopup({
          //     message: `error occured: ${errorMessage}`,
          //     type: "error",
          //     isVisible: true,
          //     buttonLabel: "Retry",
          //     buttonRoute: "/userdashboard/",
          // });
          } finally{
            setIsLoadingDetails(false);
          }
        };
      
        checkDetails();
      }, []);

  useEffect(() => {
    const fetchApplication = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/user/fetchapplication`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        console.log(response);
        
        setApplication(response.data); // Set application details
      } catch (error) {
        console.error('Error fetching application:', error);
        setPopup({
          message: 'Error fetching application details',
          type: 'error',
          isVisible: true,
          buttonLabel: '',
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchApplication();

    // Check the screen width on initial load and on window resize
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsLargeScreen(true);  // Large screen
      } else {
        setIsLargeScreen(false); // Small screen
      }
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize); // Add resize event listener

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up the event listener
    };
  }, [token]);

  const closePopup = () => {
    setPopup({ ...popup, isVisible: false });
  };

  const handleCreateApplication = () => {
   if  ( personalDetailsCompleted && !institutionDetailsCompleted) {
            navigate('/userdashboard/institutiondetails'); // Redirect to the "Create Application" page
          } else if ( institutionDetailsCompleted && !researchDetailsCompleted) {
            navigate('/userdashboard/researchdetails'); // Redirect to the "Create Application" page
             
    }  else {
      navigate('/userdashboard/personaldetails');
    }
  };



  const handleUpdateApplication = () => {
    navigate('/userdashboard/updateapplications'); // Redirect to the "Update Application" page
  };

  if (isLoading) {
    return <p>Loading application details...</p>;
  }

  return (
    <div style={{ padding: '20px', marginLeft: isLargeScreen ? '250px' : '0' }}>
      {isLoadingDeatails ? (
        <div>loading your application status..</div>
      ) : (
        <div style={{ display: "flex", gap: "20px" }}>
          <h3>Application is <span style={styles2.status}>{application ? (application.status?.status) : 'N/A'}</span></h3>
          {application ? (   
            <div>
              {application.status?.status === "Incomplete" && application.issue === "no" && (
                <button onClick={handleCreateApplication}>Create/Continue Application</button>
              )}
  
              {application.status?.status === "Incomplete" && application.issue === "yes" && (
                <button onClick={handleUpdateApplication}>Update Application</button>
              )}
  
              {application.status?.status === "Submitted" && application.issue === "no" && (
                <div></div>
              )}
            </div>
          ) : (
            <p>No application data available.</p>
          )}
        </div>
      )}
      <h4>Your Application Details:</h4>
      {application ? (
        <div>
          <h4>Personal Information</h4>
          <p><strong>Name:</strong> {`${application.title?.title} ${application.first_name} ${application.surname}`}</p>
          <p><strong>Other Name:</strong> {application.other_name || 'N/A'}</p>
          <p><strong>Gender:</strong> {application.gender?.gender || 'N/A'}</p>
          <p><strong>Phone:</strong> {application.phone || 'N/A'}</p>
          <p><strong>State:</strong> {application.state?.state || 'N/A'}</p>

          <p><strong>Profile Picture:</strong></p>
          {application.profile_picture ? (
            <img
              src={`${process.env.REACT_APP_URL}/storage/uploads/${application.profile_picture}`}
              alt="Profile"
              style={{ width: '150px', height: '150px', borderRadius: '50%' }}
            />
          ) : (
            <p>No profile picture available.</p>
          )}

          <h4>Institution Information</h4>
          <p><strong>Institution:</strong> {application.institution?.inst?.inst || 'N/A'}</p>
          <p><strong>Faculty:</strong> {application.institution?.faculty?.faculty || 'N/A'}</p>

          <h4>Research Information</h4>
          <p><strong>Research Title:</strong> {application.research?.research_title || 'N/A'}</p>
          <p><strong>Research Abstract:</strong> {application.research?.abstract || 'N/A'}</p>
          <p><strong>Research Proposal:</strong> {application.research?.research_proposal ? (
              <a href={`${process.env.REACT_APP_URL}/storage/uploads/proposals/${application.research?.research_proposal}`} target="_blank" rel="noopener noreferrer">View Research Proposal</a>
            ) : 'N/A'}</p>
          <p><strong>Research Costing:</strong> {application.research?.costing ? (
              <a href={`${process.env.REACT_APP_URL}/storage/uploads/costing/${application.research?.costing }`} target="_blank" rel="noopener noreferrer">View Costing</a>
            ) : 'N/A'}</p>

          <h4>Co-Applicants</h4>
          <p><strong>Co-Applicant 1:</strong> {application.co_applicant ? `${application.co_applicant.title?.title} ${application.co_applicant.first_name} ${application.co_applicant.last_name}` : 'None'}</p>
          <p><strong>Co-Applicant 1 CV:</strong> {application.co_applicant?.cv ? (
              <a href={`${process.env.REACT_APP_URL}/storage/uploads/cvs/${application.co_applicant?.cv}`} target="_blank" rel="noopener noreferrer">View Co-applicant CV</a>
            ) : 'N/A'}</p>
          <p><strong>Co-Applicant 2:</strong> {application.co_applicant2 ? `${application.co_applicant2.title?.title} ${application.co_applicant2.first_name} ${application.co_applicant2.last_name}` : 'None'}</p>
          <p><strong>Co-Applicant 2 CV:</strong> {application.co_applicant2?.cv ? (
              <a href={`${process.env.REACT_APP_URL}/storage/uploads/cvs/${application.co_applicant2?.cv}`} target="_blank" rel="noopener noreferrer">View Co-applicant2 CV</a>
            ) : 'N/A'}</p>

          <h4>Institution Documents</h4>
          <p><strong>Institution Support Letter:</strong> 
            {application.institution?.inst_sup_letter ? (
              <a href={`${process.env.REACT_APP_URL}/storage/uploads/letters/${application.institution.inst_sup_letter}`} target="_blank" rel="noopener noreferrer">View Support Letter</a>
            ) : 'None'}
          </p>
          <p><strong>CV:</strong> 
            {application.institution?.cv ? (
              <a href={`${process.env.REACT_APP_URL}/storage/uploads/cvs/${application.institution.cv}`} target="_blank" rel="noopener noreferrer">View CV</a>
            ) : 'None'}
          </p>

          <h2><strong>Status:</strong> {application.status?.status || 'N/A'}</h2>
        </div>
      ) : (
        <p>No application data available.</p>
      )}
      {popup.isVisible && (
        <Popup
          message={popup.message}
          type={popup.type}
          onClose={closePopup}
          buttonLabel={popup.buttonLabel}
        />
      )}
    </div>
  );
};

export default ApplicationDetail;
