import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Popup from "../Popup/Popup";


const PersonalDetailsMain = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
   const [personalDetailsCompleted, setPersonalDetailsCompleted] = useState(false);
    const [institutionDetailsCompleted, setInstitutionDetailsCompleted] = useState(false);
    const [researchDetailsCompleted, setResearchDetailsCompleted] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(false);


  const [isLoading, setIsLoading] = useState(false);
  const [popup, setPopup] = useState({ message: "", type: "", isVisible: false, buttonLabel: "", buttonRoute: "" });
  const token = localStorage.getItem('authToken');

   useEffect(() => {
      const checkDetails = async () => {
        try {
            
          // If the token is available, include it in the headers
          const headers = token ? { Authorization: `Bearer ${token}` } : {};
    
          // Fetch data for personal, institution, and research checks with Authorization headers
          const personalRes = await fetch(`${process.env.REACT_APP_URL}/api/user/personal-check`, { headers });
          const institutionRes = await fetch(`${process.env.REACT_APP_URL}/api/user/institution-check`, { headers });
          const researchRes = await fetch(`${process.env.REACT_APP_URL}/api/user/research-check`, { headers });
          // Parse JSON responses
          const personalData = await personalRes.json();
          console.log('this is personal check', personalDetailsCompleted)

          const institutionData = await institutionRes.json();
          const researchData = await researchRes.json();
    
          // Check if the response has 'data' field and set accordingly
          setPersonalDetailsCompleted(personalData.data);
          setInstitutionDetailsCompleted(institutionData.data);  // Assuming institution check returns a similar response format
          setResearchDetailsCompleted(researchData.data); // Assuming research check returns a similar response format
        } catch (error) {
          console.error("Error fetching details", error);
          const errorMessage = error?.message || "An unknown error occurred. Please try again.";
  
        //   setPopup({
        //     message: `error occured: ${errorMessage}`,
        //     type: "error",
        //     isVisible: true,
        //     buttonLabel: "Retry",
        //     buttonRoute: "/userdashboard/",
        // });
        }
      };
    
      checkDetails();
    }, []);
    

    const handleMenuClick = (path) => {
      if (path === "/userdashboard/researchdetails" && !personalDetailsCompleted) {
        setPopup({
              message: `Please fill out your Personal Details first.`,
              type: "error",
              isVisible: true,
              buttonLabel: "Proceed to Personal Details",
              buttonRoute: "/userdashboard/personaldetails",
            });      }
            else if  (path === "/userdashboard/researchdetails" && !institutionDetailsCompleted) {
    setPopup({
          message: `Please fill out your Institution Details first.`,
          type: "error",
          isVisible: true,
          buttonLabel: "Proceed to Institution Details",
          buttonRoute: "/userdashboard/institutiondetails",
        });      } else if (path === "/userdashboard/institutiondetails" && !personalDetailsCompleted) {
        setPopup({
          message: `Please fill out your Personal Details first.`,
          type: "error",
          isVisible: true,
          buttonLabel: "Proceed to Personal Details",
          buttonRoute: "/userdashboard/personaldetails",
        });  
      } else {
        navigate(path);
      }
    };
  
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    otherName: "",
    lastName: "",
    phoneNumber: "",
    gender: "",
    state: "",
    confirmPassword: "",
    profilePhoto: null,
  });

  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    profilePhoto: "",
    title: "",
    firstName: "",
    otherName: "",
    lastName: "",
    gender: "",
    phoneNumber: "",
    state: "",
    profilePhoto: "",
  });

  const [title, setTitle] = useState([]);
  const [gender, setGender] = useState([]);
  const [state, setState] = useState([]);

  useEffect(() => {
    const fetchTitle = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/alltitles`
        );
        const names = response.data.map((item) => item.title);
        setTitle(names);
      } catch (error) {
        console.error("Error fetching titles:", error);
      }
    };

    fetchTitle();
  }, []);


// fetch
  useEffect(() => {
    const fetchGender = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/allgenders`
        );
        const gender = response.data.map((item) => item.gender); // Adjust based on API response
        setGender(gender);
      } catch (error) {
        console.error("Error fetching gender:", error);
      }
    };

    fetchGender();
  }, []);

  useEffect(() => {
    const fetchState = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/allstates`
        );
        const names = response.data.map((item) => item.state);
        setState(names);
      } catch (error) {
        console.error("Error fetching States names:", error);
      }
    };

    fetchState();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setFormData({
        ...formData,
        profilePhoto: file,
      });
      setErrors({
        ...errors,
        profilePhoto: "",
      });
    } else {
      setErrors({
        ...errors,
        profilePhoto: "Please upload a valid image file",
      });
    }
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    // Validation for each field (same as before)
    if (!formData.title) {
      formErrors.title = "Title is required";
      isValid = false;
    }

    if (!formData.firstName) {
      formErrors.firstName = "First Name is required";
      isValid = false;
    }

    if (!formData.lastName) {
      formErrors.lastName = "Last Name is required";
      isValid = false;
    }

    if (!formData.phoneNumber) {
      formErrors.phoneNumber = "Phone Number is required";
      isValid = false;
    }

    if (!formData.gender) {
      formErrors.gender = "Gender is required";
      isValid = false;
    }

   

    if (!formData.state) {
      formErrors.state = "State is required";
      isValid = false;
    }

    if (!formData.profilePhoto) {
      formErrors.profilePhoto = "Profile Photo is required";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData.gender)


  

    if (validateForm()) {
      setIsLoading(true);
    //   const data = {   
    //     first_name: formData.firstName,           
    //     gender_id: formData.gender,
    //     phone: formData.phoneNumber,
    //     state: formData.state,
        
    //     surname: formData.lastName,
    //     profile_picture: base64Image,
    //     other_name: formData.otherName,
       
    //     title: formData.title,  
    // };

  

  

    console.log(formData.gender)
    try {
      // Prepare the form data
      const formDataObj = new FormData();
      formDataObj.append("title", formData.title);
      formDataObj.append("first_name", formData.firstName);
      formDataObj.append("other_name", formData.otherName);
      formDataObj.append("surname", formData.lastName);
      formDataObj.append("phone", formData.phoneNumber);
      formDataObj.append("gender_id", formData.gender);
      formDataObj.append("state", formData.state);

      if (formData.profilePhoto) {
          formDataObj.append("profile_picture", formData.profilePhoto); // Add the image file
      }

  console.log("this is the data", formDataObj)
  console.log(formData.title)
  
      const response = await fetch(`${process.env.REACT_APP_URL}/api/user/personal`, {
          method: 'POST',
          headers: {
              // 'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`  },
          body: formDataObj,
      });

      const result = await response.json();

      console.log("result", result)
      console.log(typeof(formData.profilePhoto))

      if (response.ok) {
          console.log('Personal Details Submitted successful:', result);
          // Save the token to localStorage
          // localStorage.setItem('authToken', result.token);

          setPopup({
              message: "Personal Details Submitted successful!",
              type: "success",
              isVisible: true,
              buttonLabel: "Proceed to your Enter Your Institution Details",
              buttonRoute: "/userdashboard/institutiondetails",
          });
      } else {
          console.error('Submission failed:', result);
          const errorMessages = Object.entries(result)
          .map(([key, messages]) => `${key}: ${messages.join(', ')}`)
          .join('\n');
          setPopup({
              message: `Submission Failed: ${errorMessages}`,
              type: "error",
              isVisible: true,
              buttonLabel: "Retry",
              buttonRoute: "/userdashboard/personaldetails",
          });
      }
  } catch (error) {
      console.error('Error during Login:', error);
      setPopup({
          message: "An error occurred. Please try again.",
          type: "error",
          isVisible: true,
          buttonLabel: "Retry",
          buttonRoute: "/userdashboard/personaldetails",
      });
  } finally {
      setIsLoading(false);
  }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    window.location.href = "/login"; // Redirect to login page
  };

  // Inline CSS styles
  const styles2 = {
    formRow: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "20px",
      flexDirection: isMobile ? "column" : "row", // Change direction based on screen size
    },
    formColumn: {
      width: isMobile ? "100%" : "48%", // Full width for smaller screens
      marginRight: isMobile ? "0" : "2%",
    },
    formColumnLast: {
      width: isMobile ? "100%" : "48%",
      marginRight: "0",
    },
    input: {
      width: "100%",
      padding: "8px",
      marginTop: "5px",
      borderRadius: "4px",
      border: "1px solid #ccc",
    },
    select: {
      width: "100%",
      padding: "8px",
      marginTop: "5px",
      borderRadius: "4px",
      border: "1px solid #ccc",
    },
    error: {
      color: "red",
      fontSize: "12px",
      marginTop: "5px",
    },
  };

  // Inline styles
  const styles = {
    dashboardContainer: {
      display: "flex",
      minHeight: "100vh",
    },
    sideMenuContainer: {
      flex: 1,
      padding: "20px",
      backgroundColor: "#f9f9f9",
      borderRight: "1px solid #ddd",
    },
    contentContainer: {
      flex: 3,
      padding: "20px",
      backgroundColor: "#fff",
    },
    sideMenu: {
      marginBottom: "20px",
    },
    heading: {
      marginBottom: "10px",
      color: "#333",
    },
    status: {
      fontSize: "14px",
      marginBottom: "20px",
    },
    statusOpen: {
      color: "green",
      fontWeight: "bold",
    },
    menuButton: {
      backgroundColor: "#ec3237",
      color: "white",
      border: "none",
      borderRadius: "5px",
      width: "80%",
      marginBottom: "10px",
      padding: "10px",
      cursor: "pointer",
    },
    applicationList: {
      marginBottom: "20px",
    },
    applicationListItem: {
      marginBottom: "10px",
    },
    applicationLink: {
      textDecoration: "none",
      color: "#0066cc",
    },
    applicationLinkHover: {
      textDecoration: "underline",
    },
    primaryButton: {
      backgroundColor: "#1db540",
      color: "white",
      padding: "10px 20px",
      border: "none",
      borderRadius: "5px",
      marginTop: "20px",
      cursor: "pointer",
    },
  };

  const SideMenu = () => {
 

    return (
      <div style={{
        ...styles.sideMenuContainer,
        display: isMobile && !isMenuOpen ? "none" : "block", // Hide on mobile unless menu is open
      }}>
        <h2 style={styles.heading}>Get Started</h2>
        <p style={styles.status}>
          <strong>
            Application Status: <span style={styles.statusOpen}>Open</span>
          </strong>
        </p>
        {[
          { text: "Home", path: "/userdashboard" },
          { text: "Personal Details", path: "/userdashboard/personaldetails" },
          
          {
            text: "Institution Details",
            path: "/userdashboard/institutiondetails",
          },
          { text: "Research Details", path: "/userdashboard/researchdetails" },
        ].map((menu, index) => (
          <button
          key={index}
          style={styles.menuButton}
          onClick={() => handleMenuClick(menu.path)}
          disabled={(menu.path === "/userdashboard/institutiondetails" && !personalDetailsCompleted) || 
                    (menu.path === "/userdashboard/researchdetails" && !institutionDetailsCompleted)}
        >
            {menu.text}
          </button>
        ))}
        <button style={styles.menuButton} onClick={() => handleLogout()}>
          Log Out
        </button>
      </div>
    );
  };


  return (
    <div style={styles.dashboardContainer}>
      {/* Hamburger Button for mobile */}
      {isMobile && (
        <button
          style={{
            ...styles.hamburgerButton,
            position: isMenuOpen ? "fixed" : "absolute", // Fix button at top when menu is open
            top: isMenuOpen ? "10px" : "20px", // Adjust position when the menu is open
            right: "20px", // Position at the top right
            zIndex: 1000, // Ensure it's always above other elements
          }}
          onClick={toggleMenu}
        >
          {isMenuOpen ? "X" : "☰"}
        </button>
      )}

       {/* Side menu for mobile screens */}
       {isMobile && isMenuOpen && <SideMenu />}


      {!isMobile && <SideMenu />}
      <div style={styles.contentContainer}>
      <div style={styles.applicationList}>
      <form onSubmit={handleSubmit}>
        <div className="login-top">
          <h3>Personal Details</h3>
        </div>

        {/* Profile Photo Field */}
        <div className="form-group">
          <label>Profile Photo</label>
          <input
            type="file"
            id="profilePhoto"
            name="profilePhoto"
            accept="image/*"
            onChange={handleFileChange}
            style={styles2.input}
          />
          {errors.profilePhoto && (
            <span className="error" style={styles2.error}>
              {errors.profilePhoto}
            </span>
          )}
        </div>

        {/* Flexbox for fields */}
        <div style={styles2.formRow}>
          {/* Title Field */}
          <div style={styles2.formColumn}>
            <label>Title</label>
            <select
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              style={styles2.select}
            >
              <option value="">Select Title</option>
              {title.map((name, id, index) => (
                <option key={index} value={id+1}>
                  {name}
                </option>
              ))}
            </select>
            {errors.title && (
              <span className="error" style={styles2.error}>
                {errors.title}
              </span>
            )}
          </div>
          {/* Gender */}
          <div style={styles2.formColumnLast}>
            <label>Gender</label>
            <select
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              style={styles2.select}
            >
              <option value="">Select Gender</option>
              {gender.map((name, id, index) => (
                <option key={index} value={id+1}>
                  {name}
                </option>
              ))}
            </select>
            {errors.gender && (
              <span className="error" style={styles2.error}>
                {errors.gender}
              </span>
            )}
          </div>
        </div>

        <div style={styles2.formRow}>
          {/* First Name */}
          <div style={styles2.formColumn}>
            <label>First Name</label>
            <input
              placeholder="First Name"
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              style={styles2.input}
            />
            {errors.firstName && (
              <span className="error" style={styles2.error}>
                {errors.firstName}
              </span>
            )}
          </div>

          {/* Last Name */}
          <div style={styles2.formColumnLast}>
            <label>Last Name</label>
            <input
              placeholder="Last Name"
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              style={styles2.input}
            />
            {errors.lastName && (
              <span className="error" style={styles2.error}>
                {errors.lastName}
              </span>
            )}
          </div>
        </div>

        <div style={styles2.formRow}>
          {/* Other Name */}
          <div style={styles2.formColumn}>
            <label>Other Name</label>
            <input
              placeholder="Other Name"
              type="text"
              id="otherName"
              name="otherName"
              value={formData.otherName}
              onChange={handleInputChange}
              style={styles2.input}
            />
            {errors.otherName && (
              <span className="error" style={styles2.error}>
                {errors.otherName}
              </span>
            )}
          </div>

          {/* Phone Number */}
          <div style={styles2.formColumnLast}>
            <label>Phone Number</label>
            <input
              placeholder="Phone Number"
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              style={styles2.input}
            />
            {errors.phoneNumber && (
              <span className="error" style={styles2.error}>
                {errors.phoneNumber}
              </span>
            )}
          </div>
        </div>

        <div style={styles2.formRow}>
         

          {/* State */}
          <div style={styles2.formColumnLast}>
            <label>State</label>
            <select
              name="state"
              value={formData.state}
              onChange={handleInputChange}
              style={styles2.select}
            >
              <option value="">Select State</option>
              {state.map((name, id, index) => (
                <option key={index} value={id+1}>
                  {name}
                </option>
              ))}
            </select>
            {errors.state && (
              <span className="error" style={styles2.error}>
                {errors.state}
              </span>
            )}
          </div>
        </div>

        <button type="submit" id="button" name="submit" style={styles2.input} disabled={isLoading}>
        {isLoading ? 'Submiting...' : 'Next'}
        </button>
      </form>
    </div>
      </div>
      {popup.isVisible && (
                <Popup
                    message={popup.message}
                    type={popup.type}
                    onClose={() => setPopup({ ...popup, isVisible: false })}
                    buttonLabel={popup.buttonLabel}
                    buttonRoute={popup.buttonRoute}
                />
            )}
    </div>
  );
};


export default PersonalDetailsMain;
