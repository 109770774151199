import React, { useState } from 'react';
import './App.css';
import PersonalDetailsMain from '../../components/Dashboard/UserPersonalDetailsMain';
import AdminResearchGrantDashboard from '../home/AdminResearchGrantDashboard';
import AdminBreadcrumb from '../../components/Breadcrumb/AdminBreadcrumbs';
// import ScrollToTop from '../../ScrollToTop';

function AdminDashboard() {
    const [applications, setApplications] = useState([
        { id: 1, name: "John Doe", date: "2024-12-05", status: "Pending" },
        { id: 2, name: "Jane Smith", date: "2024-12-06", status: "Approved" },
        { id: 3, name: "Alice Johnson", date: "2024-12-07", status: "Rejected" }
    ]);
    
    return (
        <div className="react-wrapper">
            <div className="react-wrapper-inner">
                <AdminBreadcrumb pageTitle=" FFSD Research Grant Admin Dashboard" />
                {/* <PersonalDetailsMain /> */}
                <AdminResearchGrantDashboard />
                
                {/* <ScrollToTop /> */}
            </div>
        </div>
    );
}

export default AdminDashboard;
